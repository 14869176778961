import React, { useEffect, useState } from 'react';
import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	FormLabel,
	Input,
	Spinner,
	Stack,
	Text,
	useToast
} from '@chakra-ui/react';
import { deleteLesson, updateLesson, addHomework as apiAddHomework, deleteHomework } from '../../services/api';
import { toBase64, uploadWithBase64 } from '../../utils/imageHelper';
import { Lesson } from './Index';
import { BsFileEarmarkMedicalFill, BsTrashFill } from 'react-icons/bs';
import { MdCancel } from 'react-icons/md';

type DrawerCreateProps = {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
	bookId: string;
	afterClose: () => void;
	lesson: Lesson;
};

export function DrawerUpdate({ isOpen, onOpen, onClose, afterClose, bookId, lesson }: DrawerCreateProps) {
	const toast = useToast();

	const [ loading, setLoading ] = useState(false);

	const [ selectImage, setSelectImage ] = useState(false);
	const [ addHomework, setAddHomework ] = useState(false);

	const [ imageFile, setImageFile ] = useState<File>();

	const [ isUploading, setIsUploading ] = useState(false);

	const [ selectPdf, setSelectPdf ] = useState(false);
	const [ pdfFile, setPdfFile ] = useState<File>();
	const [ pdf, setPdf ] = useState('');

	const [ homeworkFile, setHomeworkFile ] = useState<File>();
	const [ nomeHomework, setNomeHomework ] = useState('');
	const [ fileBase64, setFileBase64] = useState('');
	
	const [ nome, setNome ] = useState('');
	const [ canvaUrl, setCanvaUrl ] = useState('');
	const [ capa, setCapa ] = useState('');

	const resetForm = () => {
		setNome('');
		setCanvaUrl('');
		setCapa('');
	};

	useEffect(
		() => {
			if (isOpen) {
				setCanvaUrl(lesson.canvaUrl);
				setCapa(lesson.thumbnail);
				setNome(lesson.nome);
			}
		},
		[ isOpen ]
	);

	const handleSelectedFile = async (files: any) => {
		if (files && files[0].size < 10000000) {
			setSelectImage(true);
			setImageFile(files[0]);
			const picBase64 = await toBase64(files[0]);
			setCapa(`${picBase64}`);
			setImageFile(undefined);
		} else {
			toast({
				title: `Foto muito pesada, selecione outra!`,
				status: 'warning',
				isClosable: true
			});
		}
	};

	const handleSelectedFilePdf = async (files: any) => {
		if (files && files[0].size < 10000000000) {
			setPdfFile(files[0]);
			const picBase64 = await toBase64(files[0]);
			setSelectPdf(true);
			setPdf(`${picBase64}`);
			setPdfFile(undefined);
		} else {
			toast({
				title: `Foto muito pesada, selecione outra!`,
				status: 'warning',
				isClosable: true
			});
		}
	};

	const handleSelectedFileHomework = async (files: any) => {
		if (files && files[0].size < 1000000000) {
			setHomeworkFile(files[0]);
			const fileBase64 = await toBase64(files[0]);
			setFileBase64(`${fileBase64}`);
			setHomeworkFile(undefined);
		} else {
			toast({
				title: `Arquivo muito pesado, selecione outro!`,
				status: 'warning',
				isClosable: true
			});
		}
	};

	const handleSaveLesson = async () => {
		if (!canvaUrl || !nome || !capa) {
			toast({
				title: `Todos os campo são obrigatórios`,
				status: 'error',
				isClosable: true
			});
		} else {
			setLoading(true);
			if(selectImage){
				var pdfLink = ''
				if(selectPdf){
					pdfLink = await uploadWithBase64(pdf);
				}
				uploadWithBase64(capa).then((link) => {
					updateLesson( `${lesson?.id}`,{
						bookId,
						nome,
						canvaUrl: selectPdf ? pdfLink : canvaUrl,
						thumbnail: link
					}).then(() => {
						setLoading(false);
						setNome('');
						setCanvaUrl('');
						resetForm();
						onClose();
						afterClose();
						toast({
							title: `Adicionado com sucesso!`,
							status: 'success',
							isClosable: true
						});
					});
				});
			}else{
				var pdfLink = ''
				if(selectPdf){
					pdfLink = await uploadWithBase64(pdf);
				}
				updateLesson( lesson?.id,{
					bookId,
					nome,
					canvaUrl: selectPdf ? pdfLink : canvaUrl,
					thumbnail: capa
				}).then(() => {
					setLoading(false);
					setNome('');
					setCanvaUrl('');
					resetForm();
					onClose();
					afterClose();
					toast({
						title: `Adicionado com sucesso!`,
						status: 'success',
						isClosable: true
					});
				});
			}
		}
	};

	const handleDeleteLesson = (id: string) => {
		deleteLesson(id)
			.then(() => {
				onClose();
				afterClose();
			})
			.catch((err) => {
				toast({
					title: `${err}`,
					status: 'success',
					isClosable: true
				});
				onClose();
			});
	};

	const handleDeleteHomework = (id: string) => {
		deleteHomework(id)
			.then(() => {
				onClose();
				afterClose();
			})
			.catch((err:any) => {
				toast({
					title: `${err}`,
					status: 'success',
					isClosable: true
				});
				onClose();
			});
	};

	const adicionarHomework = () =>{
		if(!nome || !fileBase64){
			toast({
				title: `Todos os campo são obrigatórios`,
				status: 'error',
				isClosable: true
			});
		}else{
			setLoading(true);
			uploadWithBase64(fileBase64).then((link)=>{
				apiAddHomework({
					lessonId: lesson.id,
					materialUrl:link,
					nome:nomeHomework
				}).then(()=>{
					toast({
						title: `Adicionado com sucesso!`,
						status: 'success',
						isClosable: true
					});
					setLoading(false);
					onClose();
					afterClose();
				}).catch(()=>{
					toast({
						title: `Erro ao adicionar homework`,
						status: 'error',
						isClosable: true
					});
					setLoading(false);
					onClose();
					afterClose();
				})
			}).catch(()=>{
				toast({
					title: `Erro ao adicionar homework`,
					status: 'error',
					isClosable: true
				});
				setLoading(false);
				onClose();
				afterClose();
			})
		}
	}

	return (
		<Drawer
			isOpen={isOpen}
			placement="right"
			onClose={() => {
				resetForm();
				onClose();
				afterClose();
			}}
		>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader borderBottomWidth="1px">Atualizar Lesson</DrawerHeader>

				<DrawerBody>
					{loading ? (
						<Flex width="100%" height="90%" justifyContent="center" alignItems="center" flexDir="column">
							<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
							<Text fontSize="24px" fontWeight="bold">
								Salvando...
							</Text>
						</Flex>
					) : (
						<Stack spacing="24px">
							<Box>
								<FormLabel htmlFor="Nome">Nome da Lesson *</FormLabel>
								<Input
									placeholder="Insira o Nome da Lesson"
									onChange={(e) => setNome(e.target.value)}
									value={nome}
								/>
							</Box>
							<Box>
								<FormLabel htmlFor="CanvaUrl">Pdf *</FormLabel>
								<Input
									type="file"
									accept="application/pdf"
									onChange={(files) => handleSelectedFilePdf(files.target.files)}
								/>
							</Box>

							
							<Box>
								<Box
									width="100px"
									height="100px"
									borderWidth="1px"
									borderColor="gray.200"
									borderRadius="10px"
									bgPosition="center"
									bgSize="cover"
									bgImage={capa}
									boxShadow="sm"
								/>
								<Box>
									<FormLabel htmlFor="Capa">Capa *</FormLabel>
									<Input
										type="file"
										accept="image/*"
										onChange={(files) => handleSelectedFile(files.target.files)}
									/>
								</Box>
							</Box>
							{
								addHomework ? 
								<Box>
									<Box>
										<FormLabel htmlFor="nomeHomehork">Nome da Homework</FormLabel>
										<Input
											placeholder="Insira o nome da Homework"
											onChange={(e) => setNomeHomework(e.target.value)}
											value={nomeHomework}
										/>
									</Box>
									<Box>
										<FormLabel htmlFor="Capa">Selecione o arquivo</FormLabel>
										<Input
											type="file"
											accept="application/pdf"
											onChange={(files) => handleSelectedFileHomework(files.target.files)}
										/>
									</Box>
									<Box mt="10px">
										<Flex width="100%" justifyContent="space-between">
											<Button 
												variant="outline" 
												padding={0} 
												colorScheme="yellow" 
												onClick={()=>setAddHomework(false)} 
												isDisabled={loading}
											>
												<MdCancel />
											</Button>
											<Button colorScheme="blue" width="80%" onClick={adicionarHomework} >
												Enviar
											</Button>
										</Flex>
									</Box>
								</Box>
								:
									<Box>
										<Button colorScheme="blue" width="100%" onClick={()=>setAddHomework(true)}>
											Adicionar Homework
										</Button>
									</Box>
							}

							<Box>
								<FormLabel htmlFor="CanvaUrl">Homeworks</FormLabel>
							</Box>
							{
								lesson.homeworks.map((homework, index)=>
								<Box
									width="100%"
									height="50px"
									borderWidth="1px"
									borderColor="gray.200"
									borderRadius="10px"
									boxShadow="sm"
								>
									<Flex
										height="100%"
										justifyContent="space-around"
										alignItems="center"
									>
										<Flex 
											justifyContent="center"
											alignItems="center"
											gap="10px"
										>
											<BsFileEarmarkMedicalFill size="20px"/>
											<Text fontSize="18px" fontWeight="semibold" >
												{homework.nome}
											</Text>
										</Flex>
										<Flex>
											<Button variant="outline" padding={0} colorScheme="red" onClick={()=>handleDeleteHomework(homework.id)} isDisabled={loading}>
												<BsTrashFill />
											</Button>
										</Flex>
									</Flex>
								</Box>
								)
							}
							
							<Box height={50}>
							</Box>
						</Stack>
					)}
				</DrawerBody>

				<DrawerFooter borderTopWidth="1px">
					<Button variant="outline" mr={3}  colorScheme="red" onClick={()=>handleDeleteLesson(lesson.id)} isDisabled={loading}>
						Deletar
					</Button>
					<Button variant="outline" mr={3} onClick={onClose} isDisabled={loading}>
						Voltar
					</Button>
					<Button colorScheme="blue" isDisabled={loading} onClick={handleSaveLesson}>
						Salvar
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
