import {
	Avatar,
	Box,
	Button,
	Flex,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	Spinner,
	Stack,
	useToast,
	Text
} from '@chakra-ui/react';
import { AlunoUpdateType, liberarBook, listarBooks, listarBooksPorAluno, removerBook } from '../../services/api';
import { useEffect, useState } from 'react';
import { BookGet } from '../Books';
import { BsTrashFill } from 'react-icons/bs';
import { BiBookBookmark } from 'react-icons/bi';
import { AlunosType } from '.';

type LiberarMaterial = {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
	afterClose: () => void;
	aluno: AlunosType;
};

export function LiberarMaterial({ isOpen, onOpen, onClose, aluno, afterClose }: LiberarMaterial) {
	const toast = useToast();
	const [ books, setBooks ] = useState<BookGet[]>([]);
	const [ booksAluno, setBooksAluno ] = useState<BookGet[]>([]);
	const [ materialId, setMaterialId ] = useState<string>('');
	const [ isLoading, setIsLoading ] = useState<boolean>(true);

	useEffect(
		() => {
			listarBooks()
				.then((response) => {
					setBooks(response.data);
					listarBooksPorAluno(aluno.id)
						.then((response) => {
							setBooksAluno(response.data);
							setIsLoading(false);
						})
						.catch((error) => {
							if (!error.response) return;
							toast({
								title: `Não foi possível listar os materiais.`,
								status: 'error',
								isClosable: true
							});
						});
				})
				.catch((error) => {
					if (!error.response) return;
					toast({
						title: `Não foi possível listar os materiais.`,
						status: 'error',
						isClosable: true
					});
				});
		},
		[ isOpen, aluno ]
	);

	const adicionarBook = () => {
		if (materialId) {
			setIsLoading(true);
			liberarBook(aluno.id, materialId)
				.then((res) => {
					setIsLoading(false);
					onClose();
					toast({
						title: `Material liberado para o aluno.`,
						status: 'success',
						isClosable: true
					});
					afterClose();
				})
				.catch((err) => {
					setIsLoading(false);
					onClose();
					toast({
						title: `${err}`,
						status: 'error',
						isClosable: true
					});
					afterClose();
				});
		} else {
			toast({
				title: `Selecione o material`,
				status: 'error',
				isClosable: true
			});
		}
	};

	const handleRemoveBook = (materialId: string) => {
		removerBook(aluno.id, materialId)
			.then(() => {
				toast({
					title: `Removido com sucesso!`,
					status: 'success',
					isClosable: true
				});
				onClose();
				afterClose();
			})
			.catch((err) => {
				toast({
					title: `${err}`,
					status: 'error',
					isClosable: true
				});
				onClose();
			});
	};

	return (
		<Modal
			onClose={() => {
				onClose();
				afterClose();
			}}
			isOpen={isOpen}
			isCentered
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>Liberar Material</ModalHeader>
				<ModalCloseButton />
				{!isLoading ? (
					<ModalBody>
						<Stack spacing="24px">
							<Box>
								<FormLabel>Aluno</FormLabel>
								<Flex
									gap={'10px'}
									alignItems="center"
									justifyContent="center"
									paddingTop="10px"
									paddingBottom="10px"
									borderWidth="1px"
									borderColor="gray.200"
									borderRadius="10px"
								>
									<Avatar
										size="sm"
										name={`${aluno.nome} ${aluno.sobrenome}`}
										// src={aluno.avatarUrl}
									/>
									{`${aluno.nome} ${aluno.sobrenome}`}
								</Flex>
							</Box>
							<Box>
								<FormLabel>Materiais Disponíveis</FormLabel>
								{booksAluno &&
									booksAluno.map((book, index) => (
										<Flex
											gap={'10px'}
											alignItems="center"
											justifyContent="space-around"
											paddingTop="5px"
											paddingBottom="5px"
											borderWidth="1px"
											borderColor="gray.200"
											borderRadius="10px"
											mb="10px"
										>
											<Flex alignItems="center" justifyContent="center" gap="10px">
												<BiBookBookmark size="20px" />
												<Text fontSize="18px" fontWeight="semibold">
													{`${book.nome}`}
												</Text>
											</Flex>
											<Button
												variant="outline"
												padding={0}
												colorScheme="red"
												onClick={() => handleRemoveBook(book.id)}
											>
												<BsTrashFill />
											</Button>
										</Flex>
									))}
							</Box>
							<Box>
								<FormLabel htmlFor="nome">Material *</FormLabel>
								<Select
									id="professor"
									defaultValue="selecione"
									onChange={(e) => setMaterialId(e.target.value)}
								>
									<option value="" disabled>
										Selecione o Material
									</option>
									{books.map((item, index) => {
										return (
											<option
												value={item.id}
												key={index}
												style={{ color: 'black' }}
											>{`${item.nome}`}</option>
										);
									})}
								</Select>
							</Box>
						</Stack>
					</ModalBody>
				) : (
					<ModalBody>
						<Stack spacing="24px">
							<Box>
								<Flex
									width="100%"
									height="90%"
									justifyContent="center"
									alignItems="center"
									flexDir="column"
								>
									<Spinner
										thickness="4px"
										speed="0.65s"
										emptyColor="gray.200"
										color="blue.500"
										size="xl"
									/>
									<Text fontSize="24px" fontWeight="bold">
										Carregando...
									</Text>
								</Flex>
							</Box>
						</Stack>
					</ModalBody>
				)}
				<ModalFooter>
					<Button onClick={onClose} mr="10px" isDisabled={isLoading}>
						Fechar
					</Button>
					<Button colorScheme="blue" isDisabled={isLoading} onClick={adicionarBook}>
						Salvar
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
}
