import { IUser } from "../../contexts/AuthContext";
import { sendToken, updateUsuario } from "../../services/api";
import {
  Input,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  Button,
  Stack,
  FormControl,
  FormLabel,
  Avatar,
  Heading,
  AvatarBadge,
  IconButton,
  Flex,
  useToast,
  Link,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FaPencilAlt } from "react-icons/fa";
import useAuth from "../../hooks/useAuth";
import { toBase64, uploadWithBase64 } from "../../utils/imageHelper";
import { useNavigate } from "react-router-dom";

type ModalPerfil = {
  onClose: () => void;
  isOpen: boolean;
  onOpen: () => void;
  user: IUser | undefined;
};

type UserTypePost = {
  avatarUrl: string;
  nome: string;
  sobrenome: string;
  email: string;
};

export const ModalPerfil = ({ onOpen, isOpen, onClose, user }: ModalPerfil) => {
  const [imageFile, setImageFile] = useState<File>();
  const [image64, setImage64] = useState('');
  const [link, setLink] = useState('');
  const [downloadURL, setDownloadURL] = useState('');
  const [loading, setLoading] = useState(false);
  const [updateImage, setUpdateImage] = useState<boolean>(false);

  const [avatarUrl, setAvatarUrl] = useState('');
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [email, setEmail] = useState('');

  const { setUser } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();
  const inputFile = useRef<any>();

  useEffect(() => {
    if(isOpen){
      setImage64('')
    }
  }, [isOpen]);

  useEffect(() => {
    if(user){
      setAvatarUrl(user.avatar);
      setNome(user.name.split(" ")[0]);
      setSobrenome(user.name.split(" ")[1]);
      setEmail(user.username);
    }
  }, [user]);

  const handleChangeAvatar = () => {
    inputFile.current.click();
  };

  const handleSelectedFile = async (files: any) => {
    if (files && files[0].size < 10000000) {
      setImageFile(files[0]);
      const picBase64 = await toBase64(files[0]);
      setImage64(`${picBase64}`);
      setUpdateImage(true);
    } else {
      toast({
        title: `Foto muito pesada, selecione outra!`,
        status: "warning",
        isClosable: true,
      });
    }
  };

  const handleToken = () => {
    sendToken(user?.username+'')
      .then(() => {
        navigate('/change-password/' + user?.username);
      })
      .catch(() => {
        toast({
          title: `Não foi possível enviar o código.`,
          status: 'error',
          isClosable: true
        });
      });
  }

  const handleUpdateUsuario = async () => {
    setLoading(true);
    if(updateImage){
      uploadWithBase64(image64).then((link)=>{
        setTimeout(()=>{
          updateUsuario(`${user?.username}`,{
            avatarUrl: link,
            email,
            nome,
            sobrenome
          })
            .then(() => {
              setUser({
                avatar: link,
                name: `${nome} ${sobrenome}`,
                username: email,
                userType: user?.userType,
                sub: user?.sub,
              });
              setLoading(false);
              setImageFile(undefined);
              setUpdateImage(false);
              onClose();
              toast({
                title: `Perfil editado com sucesso.`,
                status: "success",
                isClosable: true,
              });
              inputFile.current.value = null
            })
            .catch((error) => {
              setLoading(false);
              if (!error.response) return;
              toast({
                title: `Não foi possível editar seu perfil.`,
                status: "error",
                isClosable: true,
              });
            });
        }, 1000)
      });
    }else{
      updateUsuario(`${user?.username}`,{
        avatarUrl,
        email,
        nome,
        sobrenome
      })
      .then(() => {
        setLoading(false);
        setImageFile(undefined);
        setUser({
          avatar: avatarUrl,
          name: `${nome} ${sobrenome}`,
          username: email,
          userType: user?.userType,
          sub: user?.sub,
        });
        setUpdateImage(false);
        onClose();
        toast({
          title: `Perfil editado com sucesso.`,
          status: "success",
          isClosable: true,
        });
        inputFile.current.value = null
      })
      .catch((error) => {
        setLoading(false);
        if (!error.response) return;
        toast({
          title: `Não foi possível editar seu perfil.`,
          status: "error",
          isClosable: true,
        });
      });
    }
  };

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Heading
              lineHeight={1.1}
              fontSize={{ base: "2xl", sm: "3xl" }}
              marginBottom={"20px"}
            >
              Meu Perfil
            </Heading>
            {loading ? (
              <Stack>
                <Flex width="100%" height="90%" justifyContent="center" alignItems="center" flexDir="column">
                  <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
                  <Text fontSize="24px" fontWeight="bold">
                    Salvando...
                  </Text>
                </Flex>
              </Stack>
            ) : (
              <>
                <FormControl id="userName">
                  <FormLabel>Avatar</FormLabel>
                  <Flex marginBottom={"20px"}>
                    <Avatar size="xl" src={image64 ? image64 : user?.avatar }>
                      <AvatarBadge
                        as={IconButton}
                        size="sm"
                        rounded="full"
                        top="-10px"
                        colorScheme="red"
                        aria-label="remove Image"
                        icon={<FaPencilAlt />}
                        onClick={handleChangeAvatar}
                      />
                    </Avatar>
                    <Input
                      display="none"
                      type="file"
                      ref={inputFile}
                      onChange={(files) =>
                        handleSelectedFile(files.target.files)
                      }
                      accept="image/*"
                      multiple={false}
                    />
                  </Flex>
                </FormControl>

                <Flex gap={"15px"}>
                  <FormControl id="userName" isRequired>
                    <FormLabel>Nome</FormLabel>
                        <Input
                          placeholder="Nome"
                          _placeholder={{ color: "gray.500" }}
                          type="text"
                          value={nome}
                          onChange={(e)=>setNome(e.target.value)}
                        />
                  </FormControl>
                  <FormControl id="lastName" isRequired>
                    <FormLabel>Sobrenome</FormLabel>
                        <Input
                          placeholder="Sobrenome"
                          _placeholder={{ color: "gray.500" }}
                          type="text"
                          value={sobrenome}
                          onChange={(e)=>setSobrenome(e.target.value)}
                        />
                  </FormControl>
                </Flex>
                <FormControl id="email" isRequired marginBottom="10px">
                  <FormLabel>Email address</FormLabel>
                      <Input
                        placeholder="your-email@example.com"
                        _placeholder={{ color: "gray.500" }}
                        type="email"
                        value={email}
                        onChange={(e)=>setEmail(e.target.value)}
                      />
                </FormControl>
                <Link onClick={handleToken}>Trocar minha senha.</Link>
            <Stack
              spacing={6}
              direction={["column", "row"]}
              marginTop={"20px"}
              marginBottom={"20px"}
            >
              <Button
                bg={"red.400"}
                color={"white"}
                w="full"
                _hover={{
                  bg: "red.500",
                }}
                onClick={() => {
                  onClose();
                  inputFile.current.files = null;
                }}
              >
                Cancelar
              </Button>
              <Button
                bg={"blue.400"}
                color={"white"}
                w="full"
                _hover={{
                  bg: "blue.500",
                }}
                onClick={handleUpdateUsuario}
              >
                Salvar
              </Button>
            </Stack>
              </>
            )}
          </ModalBody>
        </ModalContent>
    </Modal>
  );
};
