import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Flex, Spinner, Text, useToast } from '@chakra-ui/react';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function Teste() {
	const toast = useToast();
	const [ denied, setDenied ] = useState(false);
	const [ loading, setLoading ] = useState(true);
	const [ numPages, setNumPages ] = useState(null);

	//@ts-ignore
	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		setLoading(false);
		document.addEventListener('keydown', function(event) {
			const keys = [ 91, 16, 17, 18 ];
			if (keys.includes(event.keyCode) || event.keyCode === 91) {
				alert('OPAA+ ' + event.keyCode);
			}
		});
	}

	return (
		<div style={{ width: '100%', height: '100%' }}>
			<Document
				file="https://firebasestorage.googleapis.com/v0/b/projetcs-storage.appspot.com/o/windfall%2Fmateriais%2F1.%20LESSON%201.pdf?alt=media&token=354e08f9-b5b1-4033-adb0-694fade1e5e1"
				onLoadSuccess={onDocumentLoadSuccess}
			>
				<Page pageNumber={1} />
			</Document>
		</div>
	);
}
