import React, { useState } from 'react';
import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	FormLabel,
	Input,
	Select,
	Spinner,
	Stack,
	Text,
	useToast
} from '@chakra-ui/react';
import { adicionarBook } from '../../services/api';
import { toBase64, uploadWithBase64 } from '../../utils/imageHelper';

type DrawerCreateProps = {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
	afterClose: () => void;
};

export function DrawerCreate({ isOpen, onOpen, onClose, afterClose }: DrawerCreateProps) {
	const toast = useToast();

	const [ loading, setLoading ] = useState(false);

	const [ selectImage, setSelectImage ] = useState(false);
	const [ imageFile, setImageFile ] = useState<File>();
	const [ isUploading, setIsUploading ] = useState(false);

	const [ nome, setNome ] = useState('');
	const [ descricao, setDescricao ] = useState('');
	const [ capa, setCapa ] = useState('');
	const [ idioma, setIdioma ] = useState('Inglês');
	const [ nivel, setNivel ] = useState('Beginner');

	const handleSalvar = () => {
		setLoading(true);
		if (!capa) {
			toast({
				title: `A capa é obrigatória!`,
				status: 'error',
				isClosable: true
			});
			setLoading(false);
		} else if (!nome || !descricao) {
			toast({
				title: `Verifique todos os campos obrigatórios!`,
				status: 'error',
				isClosable: true
			});
			setLoading(false);
		} else {
			setIsUploading(true);
			uploadWithBase64(capa).then((link) => {
				adicionarBook({
					capa: link,
					descricao,
					idioma,
					nivel,
					nome
				})
					.then(() => {
						setIsUploading(false);
						onClose();
						setCapa('');
						setDescricao('');
						setIdioma('');
						setNivel('');
						setNome('');
						toast({
							title: `Livro adicionado com sucesso!`,
							status: 'success',
							isClosable: true
						});
						setLoading(false);
						onClose();
						afterClose();
					})
					.catch((err) => {
						toast({
							title: `Erro ao adicionar livro! ${err}`,
							status: 'error',
							isClosable: true
						});
						setLoading(false);
					});
			});
		}
	};

	const resetForm = () => {
		setCapa('');
		setDescricao('');
		setIdioma('');
		setNivel('');
		setNome('');
	};

	const handleSelectedFile = async (files: any) => {
		if (files && files[0].size < 10000000) {
			setSelectImage(true);
			setImageFile(files[0]);
			const picBase64 = await toBase64(files[0]);
			setCapa(`${picBase64}`);
			setImageFile(undefined);
		} else {
			toast({
				title: `Foto muito pesada, selecione outra!`,
				status: 'warning',
				isClosable: true
			});
		}
	};

	return (
		<Drawer
			isOpen={isOpen}
			placement="right"
			onClose={() => {
				resetForm();
				onClose();
				afterClose();
			}}
		>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader borderBottomWidth="1px">Novo Book</DrawerHeader>

				<DrawerBody>
					{loading ? (
						<Flex width="100%" height="90%" justifyContent="center" alignItems="center" flexDir="column">
							<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
							<Text fontSize="24px" fontWeight="bold">
								Salvando...
							</Text>
						</Flex>
					) : (
						<Stack spacing="24px">
							<Box>
								<FormLabel htmlFor="nome">Nome do Book *</FormLabel>
								<Input
									placeholder="Insira o Nome do book"
									onChange={(e) => setNome(e.target.value)}
									value={nome}
								/>
							</Box>
							<Box>
								<FormLabel htmlFor="Descrição">Descrição *</FormLabel>
								<Input
									placeholder="Insira a Descrição"
									onChange={(e) => setDescricao(e.target.value)}
									value={descricao}
								/>
							</Box>

							<Box>
								<FormLabel htmlFor="idioma">Idioma *</FormLabel>
								<Select
									defaultValue="selecione"
									onChange={(e) => setIdioma(e.target.value)}
									value={idioma}
								>
									<option value="Inglês">Inglês</option>
									<option value="Português">Português</option>
									<option value="Italiano">Italiano</option>
									<option value="Espanhol">Espanhol</option>
									<option value="Francês">Francês</option>
								</Select>
							</Box>
							<Box>
								<FormLabel htmlFor="Nível">Nível *</FormLabel>
								<Select
									defaultValue="selecione"
									onChange={(e) => setNivel(e.target.value)}
									value={nivel}
								>
									<option value="Beginner">Beginner</option>
									<option value="Intermediate">Intermediate</option>
									<option value="Advanced">Advanced</option>
								</Select>
							</Box>
							<Box
								width="100%"
								height="150px"
								borderWidth="1px"
								borderColor="gray.200"
								borderRadius="10px"
								bgPosition="center"
								bgSize="cover"
								bgImage={capa}
							/>
							<Box>
								<FormLabel htmlFor="Capa">Capa *</FormLabel>
								<Input
									type="file"
									accept="image/*"
									onChange={(files) => handleSelectedFile(files.target.files)}
								/>
							</Box>
						</Stack>
					)}
				</DrawerBody>

				<DrawerFooter borderTopWidth="1px">
					<Button variant="outline" mr={3} onClick={onClose} isDisabled={loading}>
						Voltar
					</Button>
					<Button colorScheme="blue" isDisabled={loading} onClick={handleSalvar}>
						Salvar
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
