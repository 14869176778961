import Aviso from '../../components/Aviso';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import useAuth from '../../hooks/useAuth';
import { buscarAlunoPorEmail, listarAulasPorAluno } from '../../services/api';
import { Avatar, Badge, Box, Flex, Skeleton, Stack, Table, TableCaption, TableContainer, Tbody, Td, Tfoot, Th, Thead, Tr, useStatStyles } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

interface AulaAluno {
	id: string;
    titulo : string;
    alunoId : string;
    materialId: string;
    professorId: string;
    data: string;
    status: string;
    professor: {
      nome: string;
      sobrenome: string;
      email: string;
      userId: string;
      avatarUrl: string;
    }
}

export default function Portal() {
	const { user } = useAuth();
	const [aulas, setAulas] = useState<AulaAluno[]>([]);
	const [loading, setLoading] = useState(true);

	useEffect(()=>{
		user?.username && buscarAlunoPorEmail(user?.username).then((response)=>{
			listarAulasPorAluno(response.data.id).then((response)=>{
				setAulas(response.data);
				setLoading(false)
			})
		});
	}, []);

	return (
		<>
			<NavBar />
			<Flex width={'100%'} height={"85vh"}  alignItems="center" bgColor="gray.200" overflowX="unset" flexDir="column">
				<Aviso/>
				{
					loading ? 
					<Flex width={'90%'}>
						<Stack>
							<Skeleton height='40px' />
							<Skeleton height='40px' />
							<Skeleton height='40px' />
						</Stack>
					</Flex>
					:
			<TableContainer 
				width={'90%'} 
				height="fit-content" 
				backgroundColor={"#FFF"} 
				borderRadius="10px" 
				marginTop="50px"
			>
						<Table variant='simple'>
							<TableCaption>Próximas Aulas</TableCaption>
							<Thead>
							<Tr>
								<Th>Professor</Th>
								<Th>Assunto</Th>
								<Th>Data</Th>
								<Th>Status</Th>
							</Tr>
							</Thead>
							<Tbody>
								{aulas
								.sort((a, b) => {
									if (a.data > b.data) return -1;
									if (a.data < b.data) return 1;
									return 0;
								})
								.map((item, key)=>{
									return(
									<Tr key={key}>
										<Td>
											<Flex gap={"10px"} alignItems={"center"}>
												<Avatar
													size="sm"
													name={`${item.professor.nome} ${item.professor.sobrenome}`}
													src={item.professor.avatarUrl}
												/>
												{`${item.professor.nome} ${item.professor.sobrenome}`}
											</Flex>
										</Td>
										<Td>{item.titulo}</Td>
										<Td>{new Date(item.data).toLocaleString("pt-br", {
											day: "2-digit",
											month: "2-digit",
											year: "2-digit",
											hour:"2-digit",
											minute:"2-digit"
										  })}</Td>
										  <Td>
											{item.status === "CONCLUIDA" ? (
												<Badge colorScheme="green">{item.status}</Badge>
											) : (
												<Badge colorScheme="orange">{item.status}</Badge>
											)}
										  </Td>
									</Tr>)
								})}
							</Tbody>
						</Table>
				</TableContainer>
				}
			</Flex>
			<Footer />
		</>
	);
}
