import React, { useCallback, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	FormLabel,
	Input,
	Select,
	Spinner,
	Stack,
	Text,
	useToast
} from '@chakra-ui/react';
import { ProfessoresGet } from '../Professores';
import { deleteProfessor, updateProfessor } from '../../services/api';
import { formatCpf } from '../../utils/mask';

type DrawerCreateProps = {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
	afterClose: () => void;
	professor: ProfessoresGet;
};

export function DrawerUpdate({ isOpen, onOpen, onClose, afterClose, professor }: DrawerCreateProps) {
	const toast = useToast();

	const [ loading, setLoading ] = useState(false);

	const [ nome, setNome ] = useState<string>('');
	const [ sobrenome, setSobrenome ] = useState<string>('');
	const [ cpf, setCpf ] = useState<string>('');
	const [ email, setEmail ] = useState<string>('');

	useEffect(() => {
		setCpf(professor.cpf);
		setEmail(professor.email);
		setNome(professor.nome);
		setSobrenome(professor.sobrenome);
	}, []);

	useEffect(
		() => {
			setCpf(professor.cpf);
			setEmail(professor.email);
			setNome(professor.nome);
			setSobrenome(professor.sobrenome);
		},
		[ professor ]
	);

	const handleSalvar = () => {
		if (!nome || !sobrenome || !cpf || !email) {
			return toast({
				title: `Todos os campos são obrigatórios!`,
				status: 'error',
				isClosable: true
			});
		}
		setLoading(true);
		updateProfessor({
			cpf,
			email,
			nome,
			sobrenome
		})
			.then(() => {
				setLoading(false);
				toast({
					title: `Professor(a) atualizado com sucesso.`,
					status: 'success',
					isClosable: true
				});
				resetForm();
				onClose();
				afterClose();
			})
			.catch((error) => {
				setLoading(false);
				if (!error.response) return;
				toast({
					title: `Não foi possível atualizar professor(a).`,
					status: 'error',
					isClosable: true
				});
				resetForm();
			});
	};

	const resetForm = () => {
		setCpf('');
		setEmail('');
		setNome('');
		setSobrenome('');
	};

	return (
		<Drawer
			isOpen={isOpen}
			placement="right"
			onClose={() => {
				resetForm();
				onClose();
				afterClose();
			}}
		>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader borderBottomWidth="1px">Editar Professor</DrawerHeader>

				<DrawerBody>
					{loading ? (
						<Flex width="100%" height="90%" justifyContent="center" alignItems="center" flexDir="column">
							<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
							<Text fontSize="24px" fontWeight="bold">
								Carregando...
							</Text>
						</Flex>
					) : (
						<Stack spacing="24px">
							<Box>
								<FormLabel htmlFor="nome">Nome *</FormLabel>
								<Input
									id="nome"
									placeholder="Insira o Nome"
									onChange={(e) => setNome(e.target.value)}
									value={nome}
								/>
							</Box>
							<Box>
								<FormLabel htmlFor="Sobrenome">Sobrenome *</FormLabel>
								<Input
									id="Sobrenome"
									placeholder="Insira o Sobrenome"
									onChange={(e) => setSobrenome(e.target.value)}
									value={sobrenome}
								/>
							</Box>

							<Box>
								<FormLabel htmlFor="cpf">Cpf *</FormLabel>
								<Input
									id="cpf"
									placeholder="Insira o Cpf"
									onChange={(e) => setCpf(formatCpf(e.target.value))}
									value={cpf}
									maxLength={14}
								/>
							</Box>
							<Box>
								<FormLabel htmlFor="Email">Email *</FormLabel>
								<Input
									id="Email"
									placeholder="Insira o Email"
									onChange={(e) => setEmail(e.target.value)}
									type="email"
									value={email}
								/>
							</Box>
						</Stack>
					)}
				</DrawerBody>

				<DrawerFooter borderTopWidth="1px">
					<Button variant="outline" mr={3} onClick={onClose} isDisabled={loading}>
						Voltar
					</Button>
					<Button colorScheme="blue" mr={3} isDisabled={loading} onClick={handleSalvar}>
						Salvar
					</Button>
					<Button
						colorScheme="red"
						onClick={() => {
							deleteProfessor(professor.email).then(() => {
								onClose();
								afterClose();
							});
						}}
						isDisabled={loading}
					>
						Delete
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
