import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Avatar,
	Badge,
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputLeftElement,
	Select,
	Spinner,
	Table,
	TableCaption,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
	useToast
} from '@chakra-ui/react';
import DashLayout from '../../components/DashLayout';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { GiSpellBook } from 'react-icons/gi';
import { useCallback, useEffect, useRef, useState } from 'react';
import { AulaType, listarAlunos, listarAulasPorProfessorId, listarProfessores } from '../../services/api';
import { ProfessoresGet } from '../Professores';
import { DrawerCreate } from './DrawerCreate';
import { AulaDrawerProps, DrawerUpdate } from './DrawerUpdate';
import useAuth from '../../hooks/useAuth';
import { BsCollectionPlayFill } from 'react-icons/bs';

export interface AlunosType {
	nome: string;
	sobrenome: string;
	cpf: string;
	email: string;
	id: string;
	user: {
		ativo: true;
		avatarUrl: string;
	};
	userId: string;
	professorId:string;
}
interface AulasGet {
	id: string;
	titulo: string;
	data: string;
	alunoId: string;
	professorId: string;
	materialId: string;
	status: string;
	aluno: {
		nome: string;
		avatarUrl: string;
		sobrenome: string;
		email: string;
		userId: string;
	};
}

const professorDefault:ProfessoresGet = {
	cpf:'',
	email:'',
	id:'',
	nome:'',
	sobrenome:'',
	user:{
		ativo:true,
		avatarUrl:''
	},
	userId:''
}

const alunoDefault:AlunosType = {
	cpf:'',
	email:'',
	id:'',
	nome:'',
	sobrenome:'',
	user:{
		ativo:true,
		avatarUrl:''
	},
	userId:'',
	professorId:''
}

const aulaDefault:AulaDrawerProps = {
	id:''
,	alunoId:'',
	data:'',
	materialId:'',
	professorId:'',
	status:'',
	titulo:''
}

export const Aulas = () => {
	const [ aulas, setAulas ] = useState<AulasGet[]>([]);
	const [ professores, setProfessores ] = useState<ProfessoresGet[]>([]);
	const [ professorId, setProfessorId ] = useState<string>('');
	const [ search, setSearch ] = useState<string>('');
	const [ professor, setProfessor ] = useState<ProfessoresGet>(professorDefault);
	const [ aluno, setAluno ] = useState<AlunosType>(alunoDefault);
	const [ aulaSelected, setAulaSelected ] = useState<AulaDrawerProps>(aulaDefault);
	const [ alunos, setAlunos ] = useState<AlunosType[]>([]);
	const [ alunosProfessor, setAlunosProfessor ] = useState<AlunosType[]>([]);
	const [ loading, setLoading ] = useState<boolean>(true);
	const [ updateData, setUpdateData ] = useState<boolean>(true);
	const [aulaId, setAulaId] = useState('');

	const cancelRef = useRef<HTMLButtonElement>(null);
	const toast = useToast();
	const createDisclosure = useDisclosure();
	const updateDisclosure = useDisclosure();
	const alertDisclosure = useDisclosure();
	const {user, functionId} = useAuth();
	
	const buscarAulas = (id: string) => {
		setLoading(true);
		listarAulasPorProfessorId(id)
			.then((response) => {
				setLoading(false);
				setAulas(response.data);
				setUpdateData(false);
			})
			.catch((error) => {
				if (!error.response) return;
				setLoading(false);
				toast({
					title: `Não foi possível listar alunos.`,
					status: 'error',
					isClosable: true
				});
			});
	};

	const buscarProfessores = useCallback(
		() => {
			setLoading(true);
			listarProfessores()
				.then((response) => {
					setLoading(false);
					setProfessores(response.data);
				})
				.catch((error) => {
					if (!error.response) return;
					setLoading(false);
					toast({
						title: `Não foi possível listar alunos.`,
						status: 'error',
						isClosable: true
					});
				});
		},
		[ aulas ]
	);

	const buscarAlunos = useCallback(
		() => {
			setLoading(true);
			listarAlunos()
				.then((response) => {
					setLoading(false);
					setAlunos(response.data);
				})
				.catch((error) => {
					if (!error.response) return;
					setLoading(false);
					toast({
						title: `Não foi possível listar alunos.`,
						status: 'error',
						isClosable: true
					});
				});
		},
		[ alunos ]
	);

	useEffect(() => {
		buscarProfessores();
		buscarAlunos();
	}, []);

	useEffect(
		() => {
			if (updateData && professorId) buscarAulas(professorId);
		},
		[ updateData ]
	);

	const handleSelectProfessor = (id: string) => {
		buscarAulas(id);
		setProfessorId(id);
		var profe = professores.find(prof => prof.id === id)
		var alunosFilter = alunos.filter(aln => aln.professorId === id)
		
		if(profe)
			setProfessor(profe);

		if(alunosFilter)
			setAlunosProfessor(alunosFilter);

	};
	
	function openTab(aulaId: string) {
		window.open(
		  `/dashboard/materiais/aula/${aulaId}`,
		  "",
		  "popup,width=1080,height=660, left=300, top=500"
		);
		// alertDisclosure.onClose();
	  }

	return (
		<DashLayout>
			<Flex width="100%" height="100%" flexDir="column">
				<Flex width="100%" backgroundColor="white" height="130px" padding="20px" flexDir="column">
					<Text fontSize="24px" marginBottom="10px">
						Aulas
					</Text>
					<Flex gap="20px">
						<Select
							onChange={(e) => {
								handleSelectProfessor(e.target.value);
							}}
							border="none"
							bgColor="gray.300"
							width="35%"
						>
							<option value="" style={{ color: 'black' }} disabled selected>
								Selecione o Professor
							</option>;
							{professores.map((item, index) =>
								<option
									value={item.id}
									key={index}
									style={{ color: 'black' }}
								>{`${item.nome} ${item.sobrenome}`}</option>
							)}
						</Select>
						<InputGroup gap={'20px'}>
							<InputLeftElement pointerEvents="none" color={'gray.600'}>
								<FaSearch />
							</InputLeftElement>
							<Input
								type="tel"
								placeholder="Buscar aulas..."
								onChange={(e) => setSearch(e.target.value)}
								value={search}
								border="none"
								bgColor="gray.300"
							/>
							<Button leftIcon={<FaPlus />} colorScheme="blue" variant="solid" onClick={()=>{
								createDisclosure.onOpen();
							}}>
								Novo
							</Button>
						</InputGroup>
					</Flex>
				</Flex>
				<Flex width="100%" bgColor="white" height="100%">
					{!loading ? (
						<Box overflowY="auto" overflowX="auto" width={{base:"80vw", md:"100vw", sm:"100vw"}} paddingBottom="200px">
							<Table variant="simple">
								<TableCaption>Aulas</TableCaption>
								<Thead position="sticky" top={0} bgColor="white" zIndex="999">
									<Tr>
										<Th>Título</Th>
										<Th>Data</Th>
										<Th>Aluno</Th>
										<Th>Professor</Th>
										<Th>Status</Th>
										<Th>Action</Th>
									</Tr>
								</Thead>
								<Tbody>
									{aulas.
									sort((a, b) => {
										if (a.data > b.data) return -1;
										if (a.data < b.data) return 1;
										return 0;
									}).
									map((aula, index) => {
										if (
											Object.values(aula)
												.map((variavel) => variavel)
												.reduce((a, b) => (b = a + ' ' + b))
												.toLowerCase()
												.includes(search.toLowerCase())
										)
										return(<Tr
											key={index}
											_hover={{ bgColor: "gray.100", cursor: "pointer" }}
											onClick={()=>{
												var alunoSelected = alunos.find(aln => aln.id === aula.alunoId);
												alunoSelected && setAluno(alunoSelected);
												setAulaSelected(aula);
												if(aula.status === "AGENDADA")
													updateDisclosure.onOpen();
											}}
									  >
										<Td
										>
										  {aula.titulo}
										</Td>
										<Td
											onClick={()=>{
												var alunoSelected = alunos.find(aln => aln.id === aula.alunoId);
												alunoSelected && setAluno(alunoSelected);
												setAulaSelected(aula);
												if(aula.status === "AGENDADA")
													updateDisclosure.onOpen();
											}}
										>
										  {new Date(aula.data).toLocaleString("pt-br", {
											day: "2-digit",
											month: "2-digit",
											year: "2-digit",
										  })}
										</Td>
										<Td
											onClick={()=>{
												var alunoSelected = alunos.find(aln => aln.id === aula.alunoId);
												alunoSelected && setAluno(alunoSelected);
												setAulaSelected(aula);
												if(aula.status === "AGENDADA")
													updateDisclosure.onOpen();
											}}
										>
										  <Flex gap={"10px"} alignItems={"center"}>
											<Avatar
											  size="sm"
											  name={aula.aluno.nome}
											  src={aula.aluno.avatarUrl}
											/>
											{aula.aluno.nome}
										  </Flex>
										</Td>
										<Td
											onClick={()=>{
												var alunoSelected = alunos.find(aln => aln.id === aula.alunoId);
												alunoSelected && setAluno(alunoSelected);
												setAulaSelected(aula);
												if(aula.status === "AGENDADA")
													updateDisclosure.onOpen();
											}}
										>
										  {professor ? (
											<Flex gap={"10px"} alignItems={"center"}>
											  <Avatar
												size="sm"
												name={professor?.nome}
												src={professor.user.avatarUrl}
											  />
											  {`${professor.nome} ${professor.sobrenome}`}
											</Flex>
										  ) : (
											<Flex></Flex>
										  )}
										</Td>
										<Td 
											onClick={()=>{
												var alunoSelected = alunos.find(aln => aln.id === aula.alunoId);
												alunoSelected && setAluno(alunoSelected);
												setAulaSelected(aula);
												if(aula.status !== "CONCLUIDA")
													updateDisclosure.onOpen();
											}}
										>
										  {aula.status === "CONCLUIDA" ? (
											<Badge colorScheme="green">{aula.status}</Badge>
										  ) : (
											<Badge colorScheme="orange">{aula.status}</Badge>
										  )}
										</Td>

										<Td>
										<Flex gap="20px" alignItems="center">
												{aula.status != 'CONCLUIDA' && user?.userType === 'PROFESSOR' &&
												<>
													<Button
														w="fit-content"
														colorScheme="blue"
														onClick={()=>{
															openTab(aula.id);
														}}
													>
														<GiSpellBook />
													</Button>
													<Button
														w="fit-content"
														colorScheme="green"
														onClick={()=>{
															setAulaId(aula.id);
															alertDisclosure.onOpen();
														}}
													>
														<BsCollectionPlayFill/>
													</Button>
												</>
											}
											</Flex>
										</Td>
									  </Tr>)
									})}
								</Tbody>
							</Table>
						</Box>
					) : (
						<Flex width="100%" height="90%" justifyContent="center" alignItems="center" flexDir="column">
							<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
							<Text fontSize="24px" fontWeight="bold">
								Carregando...
							</Text>
						</Flex>
					)}
				</Flex>
			</Flex>
			<DrawerCreate 
				isOpen={createDisclosure.isOpen} 
				onClose={createDisclosure.onClose} 
				onOpen={createDisclosure.onOpen} 
				afterClose={()=>setUpdateData(true)} 
				alunos={alunosProfessor}
				professor={professor}
			/>
			<DrawerUpdate
				isOpen={updateDisclosure.isOpen} 
				onClose={updateDisclosure.onClose} 
				onOpen={updateDisclosure.onOpen} 
				afterClose={()=>setUpdateData(true)} 
				professor={professor}
				aluno={aluno}
				aula={aulaSelected}
			/>
			<AlertDialog
				motionPreset='slideInBottom'
				leastDestructiveRef={cancelRef}
				onClose={alertDisclosure.onClose}
				isOpen={alertDisclosure.isOpen}
				isCentered
			>
				<AlertDialogOverlay />

				<AlertDialogContent>
				<AlertDialogHeader>Você deseja iniciar a aula?</AlertDialogHeader>
				<AlertDialogCloseButton />
				<AlertDialogBody>
					Ao inciar o status da aula será alterado e o tempo iniciará a contagem, tenha uma ótima aula!
				</AlertDialogBody>
				<AlertDialogFooter>
					<Button ref={cancelRef} onClick={alertDisclosure.onClose}>
						Cancelar
					</Button>
					<Button colorScheme='red' ml={3} onClick={()=>openTab(aulaId)}>
						Iniciar
					</Button>
				</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</DashLayout>
	);
};
