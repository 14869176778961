import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Link,
	Stack,
	useToast,
	InputRightElement,
	InputGroup
} from '@chakra-ui/react';
import { useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';
import { FormError } from '../../components/DashLayout/FormError';
import Bg from '../../assets/images/background.png';
import useAuth from '../../hooks/useAuth';

export default function Login() {
	const [ username, setUsername ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ isLoading, setIsLoading ] = useState(false);
	const [ formError, setFormError ] = useState({
		username: false,
		password: false
	});

	const navigate = useNavigate();

	const toast = useToast();
	const { login } = useAuth();

	const [ show, setShow ] = useState(false);
	const handleClick = () => setShow(!show);

	const handleLogin = async () => {
		if (!username || !password) {
			setFormError({
				password: !password ? true : false,
				username: !username ? true : false
			});
			return;
		}
		setIsLoading(true);
		login({ username, password }).then((res) => {
			console.log('executou');
			if (!res) {
				setIsLoading(false);
			}
		});
	};
	return (
		<Stack minH={'100vh'} bgImage={Bg}>
			<Flex p={8} flex={1} align={'center'} justify={'center'}>
				<Stack
					spacing={4}
					w={'full'}
					maxW={'md'}
					align={'center'}
					justify={'center'}
					backgroundColor={'blue.800'}
					padding={'40px'}
					borderRadius="20px"
				>
					<Heading color="white" fontSize={'6xl'} textAlign="center">
						WINDFALL
						<Heading color="white" fontSize={'2xl'}>
							Language Institute
						</Heading>
					</Heading>

					<FormControl id="email">
						<FormLabel color="white">Seu email</FormLabel>
						<Input
							type="email"
							placeholder="seuemail@seuemail.com"
							value={username}
							onChange={(e) => {
								setUsername(e.target.value);
							}}
							disabled={isLoading}
							color="white"
							border="none"
							bgColor="gray.600"
						/>
						{formError.username && <FormError text="Email é obrigatório!" />}
					</FormControl>
					<FormControl id="password">
						<FormLabel color="white">Senha</FormLabel>
						<InputGroup size="md">
							<Input
								type={show ? 'text' : 'password'}
								placeholder="*********"
								value={password}
								onChange={(e) => {
									setPassword(e.target.value);
								}}
								disabled={isLoading}
								color="white"
								border="none"
								bgColor="gray.600"
							/>
							<InputRightElement width="4.5rem">
								<Button h="1.75rem" size="sm" onClick={handleClick} variant={'unstyled'}>
									{show ? <AiFillEyeInvisible size={'25'} /> : <AiFillEye size={'25'} />}
								</Button>
							</InputRightElement>
						</InputGroup>
						{formError.password && <FormError text="Senha é obrigatória!" />}
					</FormControl>
					<Stack spacing={6}>
						<Stack direction={{ base: 'column', sm: 'row' }} align={'start'} justify={'space-between'}>
							<Link color={'white'} onClick={() => navigate('/forgot-password')}>
								Esqueceu sua senha?
							</Link>
						</Stack>
						<Button colorScheme={'blue'} variant={'solid'} onClick={handleLogin} isLoading={isLoading}>
							Entrar
						</Button>
					</Stack>
				</Stack>
			</Flex>
		</Stack>
	);
}
