import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { HomePage } from '../pages/Dashboard/HomePage';
import { Professores } from '../pages/Professores';
import { Alunos } from '../pages/Alunos';
import { Aulas } from '../pages/Aulas';
import { Books } from '../pages/Books';
import { Lessons } from '../pages/Lessons/Index';
import ChangePassword from '../pages/ChangePassword';
import Portal from '../pages/Portal';
import MateriaisId from '../pages/Materiais/MaterialAula';
import MateriaisIdBook from '../pages/Materiais/Material';
import useAuth from '../hooks/useAuth';
import PortalMateriais from '../pages/Portal/Materiais';
import PortalLessons from '../pages/Portal/Lessons';
import { AulasProfessor } from '../pages/AulasProfessor';
import Teste from '../pages/Materiais/TestPdf';

export const PrivateRoutes = () => {
	const {user} = useAuth();
	return (
		<Routes>
			<Route path="*" element={
				user?.userType === "ALUNO" ?
					<Navigate to="/portal" replace={true} />
				:
					<Navigate to="/dashboard" replace={true} />
			} />
			<Route path="/dashboard" element={<HomePage />} />
			<Route path="/dashboard/professores" element={<Professores />} />
			<Route path="/dashboard/alunos" element={<Alunos />} />
			<Route path="/dashboard/books" element={<Books />} />
			<Route path="/dashboard/:bookId/lessons" element={<Lessons />} />
			<Route path="/dashboard/aulas" element={
					user?.userType === "PROFESSOR" ?
						<AulasProfessor />
					:
						<Aulas/>
				}  
			/>
			<Route path="/change-password/:email" element={<ChangePassword />} />
			<Route path="/dashboard/materiais/aula/:aulaId" element={<MateriaisId />} />
			<Route path="/dashboard/materiais/teste/" element={<Teste />} />
			<Route path="/dashboard/materiais/:bookId" element={<MateriaisIdBook />} />
			<Route path="/portal" element={<Portal />} />
			<Route path="/portal/books" element={<PortalMateriais />} />
			<Route path="/portal/books/:bookId" element={<PortalLessons />} />
		</Routes>
	);
};
