import { Flex, Text, Spinner, useToast } from '@chakra-ui/react';
import { BsFillJournalBookmarkFill } from 'react-icons/bs';
import { PiStudentFill } from 'react-icons/pi';
import { IoSchoolOutline } from 'react-icons/io5';
import { LuBuilding2 } from 'react-icons/lu';

import DashLayout from '../../components/DashLayout';
import { useEffect, useState } from 'react';
import { dashData } from '../../services/api';
import useAuth from '../../hooks/useAuth';

export const HomePage = () => {
	const [loading, setLoading] = useState<boolean>(true);
	const [aulasAgendadas, setAulasAgendadas] = useState<string>('');
	const [aulasConcluidas, setAulasConcluidas] = useState<string>('');
	const [alunos, setAlunos] = useState<string>('');
	const [professores, setProfessores] = useState<string>('');

	const {user} = useAuth();
	const toast = useToast();
	
	useEffect(()=>{
		dashData(`${user?.sub}`).then(res=>{
			setAlunos(res.data.alunos);
			setProfessores(res.data.professores);
			setAulasAgendadas(res.data.aulasAgendadas);
			setAulasConcluidas(res.data.aulasConcluidas);
			setLoading(false);
		}).catch((err)=>{
			toast({
				title: `Não foi possível carregar o livro${err}`,
				status: 'error',
				isClosable: true
			});
		})
	},[])

	return (
		<DashLayout>
			{
				loading ?
				<Flex width="100%" height="100%" alignItems="center" justifyContent="center">
					<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
					<Text fontSize="24px" fontWeight="bold">
						Carregando dados...
					</Text>
				</Flex>
				:
				<Flex flexDir={'column'} gap={'10px'} padding={'20px'} borderRadius={'10px'} marginBottom={'20px'}>
					<Flex marginBottom={'30px'} flexWrap={'wrap'} gap="25px" justifyContent="center">
						<Flex
							width={{ base: '100%', md: '20%' }}
							height="100px"
							bgColor="white"
							borderRadius="10px"
							flexDir="column"
							justifyContent="center"
							alignItems="center"
							borderColor="gray.300"
							borderWidth="1px"
							boxShadow="lg"
						>
							<Flex flexDir="column" justifyContent="center" alignItems="center">
								<Flex justifyContent="center" alignItems="center" gap="10px">
									<IoSchoolOutline size={50} color="black" />
									<Text fontWeight={700} lineHeight="25px" fontSize="16px">
										Professores<Text fontWeight={900} fontSize="32px">
											{professores}
										</Text>
									</Text>
								</Flex>
							</Flex>
						</Flex>

						<Flex
							width={{ base: '100%', md: '20%' }}
							height="100px"
							bgColor="white"
							borderRadius="10px"
							flexDir="column"
							justifyContent="center"
							alignItems="center"
							borderColor="gray.300"
							borderWidth="1px"
							boxShadow="lg"
						>
							<Flex flexDir="column" justifyContent="center" alignItems="center">
								<Flex justifyContent="center" alignItems="center" gap="10px">
									<PiStudentFill size={50} color="black" />
									<Text fontWeight={700} lineHeight="25px" fontSize="16px">
										Alunos<Text fontWeight={900} fontSize="32px">
											{alunos}
										</Text>
									</Text>
								</Flex>
							</Flex>
						</Flex>
						<Flex
							width={{ base: '100%', md: '20%' }}
							height="100px"
							bgColor="white"
							borderRadius="10px"
							flexDir="column"
							justifyContent="center"
							alignItems="center"
							borderColor="gray.300"
							borderWidth="1px"
							boxShadow="lg"
						>
							<Flex flexDir="column" justifyContent="center" alignItems="center">
								<Flex justifyContent="center" alignItems="center" gap="10px">
									<BsFillJournalBookmarkFill size={40} color="black" />
									<Text fontWeight={700} lineHeight="25px" fontSize="16px">
										Aulas Agendadas<Text fontWeight={900} fontSize="32px">
											{aulasAgendadas}
										</Text>
									</Text>
								</Flex>
							</Flex>
						</Flex>

						<Flex
							width={{ base: '100%', md: '20%' }}
							height="100px"
							bgColor="white"
							borderRadius="10px"
							flexDir="column"
							justifyContent="center"
							alignItems="center"
							borderColor="gray.300"
							borderWidth="1px"
							boxShadow="lg"
						>
							<Flex flexDir="column" justifyContent="center" alignItems="center">
								<Flex justifyContent="center" alignItems="center" gap="10px">
									<BsFillJournalBookmarkFill size={40} color="black" />
									<Text fontWeight={700} lineHeight="25px" fontSize="16px">
										Aulas Concluídas<Text fontWeight={900} fontSize="32px">
											{aulasConcluidas}
										</Text>
									</Text>
								</Flex>
							</Flex>
						</Flex>
					</Flex>
					<Flex width="100%" height="200px" alignItems="center" justifyContent="center">
						<Flex 
							width="85%" 
							height="200px"
							alignItems="center" 
							justifyContent="center" 
							bgColor="white"
							borderRadius="10px"
							boxShadow="lg"
							borderColor="gray.300"
							borderWidth="1px"
						>
					<LuBuilding2 size="30px" />
						<Text fontSize="24px" fontWeight="bold" marginLeft="15px">
								Building...
							</Text>
						</Flex>
					</Flex>
				</Flex>
			}
		</DashLayout>
	);
};
