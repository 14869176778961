import React from 'react';
import useAuth from '../hooks/useAuth';
import { PrivateRoutes } from './Private.routes';
import { PublicRoutes } from './Public.routes';


function AplicationRoutes() {
    const { isLoggedin } = useAuth();
    return (
        <>
            {isLoggedin ? <PrivateRoutes /> : <PublicRoutes />}
        </>
    );
}

export default AplicationRoutes;
