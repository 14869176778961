import { Badge, Box, Button, Flex, Input, InputGroup, InputLeftElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Spinner, Stack, Text, useDisclosure, useToast } from '@chakra-ui/react';
import DashLayout from '../../components/DashLayout';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import {  HomeworkGet, buscarBookPorId } from '../../services/api';
import { BookGet } from '../Books';
import { DrawerCreate } from './DrawerCreate';
import { DrawerUpdate } from './DrawerUpdate';
import ButtonOptions from '../../components/ButtonOptions';
import useAuth from '../../hooks/useAuth';
import { BsFileEarmarkMedicalFill } from 'react-icons/bs';

export type LessonFilterTransalated = {
	lesson:string;
	review:string;
}
export type Lesson = {
	id: string;
	nome: string;
	canvaUrl: string;
	bookId: string;
	thumbnail:string;
	homeworks: HomeworkGet[];
};

const LessonDefault = {
	id: '',
	nome: '',
	canvaUrl: '',
	bookId: '',
	thumbnail:'',
	homeworks:[]
};

const LessonTranslates = {
	"Inglês":{ 
		lesson:"Lesson",
		review:"Review"
	},
	"Português":{ 
		lesson:"Lição",
		review:"Revisão"
	},
	"Italiano":{ 
		lesson:"Lezione",
		review:"Revisione"
	},
	"Francês":{ 
		lesson:"Leçon",
		review:"Examen"
	},
	"Espanhol":{ 
		lesson:"Lección",
		review:"Revisar"
	},
}

type LessonViewd = Omit<Lesson, "homeworks">;

export const Lessons = () => {
	const { bookId } = useParams();
	const toast = useToast();

	const [ lessons, setLessons ] = useState<Lesson[]>([]);
	const [ lessonsViewd, setLessonsViewd ] = useState<LessonViewd[]>([]);
	const [ homeworks, setHomeworks ] = useState<HomeworkGet[]>([]);
	const [ book, setBook ] = useState<BookGet>();
	const [ loading, setLoading ] = useState<boolean>(true);
	const [lessonSelected, setLessonSelected] = useState<Lesson>(LessonDefault);

	const [ filter, setFilter ] = useState<string>("");
	const [ search, setSearch ] = useState<string>("");

	const {user} = useAuth();
	const createDisclosure = useDisclosure();
	const updateDisclosure = useDisclosure();
	const modalDisclosure = useDisclosure();


    function justNumbers(text:string) {
        var numbers = text.replace(/[^0-9]/g,'');
        return parseInt(numbers);
    }
	
	const buscarLessons = () => {
			buscarBookPorId(`${bookId}`)
				.then((response) => {
					var lessonsData = response.data.lessons;
					lessonsData.sort();
					setBook(response.data);
					setLessons(lessonsData);
					if(filter){
						handleFilterLesson(filter);
						setLessonsViewd(lessonsData);
					}else{
						setLessonsViewd(lessonsData);
					}
					setLoading(false);
				})
				.catch((err) => {
					toast({
						title: `Não foi possível carregar o livro${err}`,
						status: 'error',
						isClosable: true
					});
				});
		}

	useEffect(
		() => {
			buscarLessons();
		},[ ]
	);

	function handleFilterLesson(type:string){
		if(book){
			//@ts-ignore
			const filter = LessonTranslates[book.idioma];
			const lessonsFiltered = lessons.filter((l)=> l.nome.toUpperCase().includes(type === "Lesson" ? String(filter.lesson).toUpperCase() : String(filter.review).toUpperCase()));
			setLessonsViewd(lessonsFiltered);
		}
	}

	useEffect(()=>{
		handleFilterLesson(filter);
	}, [filter]);

	function openTab(bookId: string) {
		window.open(`/dashboard/materiais/${bookId}`, '', 'popup,width=1080,height=660, left=300, top=500');
	}

	return (
		<DashLayout>
			<Flex width="100%" height="100%" flexDir="column">
				<Flex width="100%" backgroundColor="white" height="175px" padding="20px" flexDir="column">
					<Text fontSize="24px" marginBottom="10px">
						Lessons / {book?.nome}
						<Flex gap="10px">
							<Badge>{book?.idioma}</Badge>
							<Badge colorScheme="blue">{book?.nivel}</Badge>
						</Flex>
					</Text>
					<Flex >
						<InputGroup gap={'20px'}>
							<InputLeftElement pointerEvents="none" color={'gray.600'}>
								<FaSearch />
							</InputLeftElement>
							<Input
								type="tel"
								placeholder="Buscar lessons..."
								onChange={(e) => setSearch(e.target.value)}
								value={search}
								border="none"
								bgColor="gray.300"
							/>
							{
								user?.userType === "ADMIN" &&
								<Button leftIcon={<FaPlus />} colorScheme="blue" variant="solid" onClick={createDisclosure.onOpen}>
									Novo
								</Button>
							}
						</InputGroup>
					</Flex>
					<Flex 
						gap="10px" 
						borderRadius="10px" 
						justifyContent="center" 
						alignItems="center" 
						p="5px"
						mt="10px"
					>
                       <RadioGroup onChange={(e)=>setFilter(e)} 
							value={filter}
					   >
						<Stack direction='row'>
							<Radio value='Lesson'>Lessons</Radio>
							<Radio value='Review'>Reviews</Radio>
						</Stack>
						</RadioGroup>
                    </Flex>
				</Flex>
				<Flex
					height="100%"
					gap="20px"
					justifyContent="center"
					padding="50px"
					flexWrap="wrap"
					overflowY="scroll"
				>
					{!loading ? (
						lessonsViewd
							.sort((a, b) => {
								if (Number(a.nome.split(' ')[1]) < Number(b.nome.split(' ')[1])) return -1;
								if (Number(a.nome.split(' ')[1]) > Number(b.nome.split(' ')[1])) return 1;
								return 0;
							})
							.map((lesson, index) => {
								if (
									Object.values(lesson)
										.map((variavel) => variavel)
										.reduce((a, b) => (b = a + ' ' + b))
										.toLowerCase()
										.includes(search.toLowerCase())
								)
								return (
										<Flex
											width="280px"
											height="100px"
											bgColor="blue.700"
											borderRadius="15px"
											alignItems="center"
											padding="10px"
											gap="10px"
											key={index}
											boxShadow="lg"
										>
											<Flex
												height="80px"
												bgImage={
													lesson.thumbnail
												}
												minWidth="80px"
												maxWidth="80px"
												bgPosition="center"
												bgSize="cover"
												borderRadius="15px"
											/>
											<Flex flexDir="column">
													<Flex alignItems="center" gap="10px">
														<Text 
															color="white" 
															fontSize="20px" 
															fontWeight="800" 
															textAlign="center"
														>
															{lesson.nome}
														</Text>
														<ButtonOptions
														isBook={false}
															onView={() => {
																openTab(lesson.bookId)
															}}
															onEdit={()=>{
																const lessonEdited = lessons.find((l) => l.id == lesson.id)
																if(lessonEdited){
																	setLessonSelected(lessonEdited);
																	updateDisclosure.onOpen();
																}
															}}
															onOpen={() => {
																openTab(lesson.bookId)
															}}
															openHomeworks={()=>{
																const filtered = lessons.find(l => l.id === lesson.id)?.homeworks;
																if(filtered){
																	setHomeworks(filtered);
																	modalDisclosure.onOpen();
																}
															}}
														/>
													</Flex>
												</Flex>
										</Flex>
									)
								}
							)
					) : (
						<Flex width="100%" height="90%" justifyContent="center" alignItems="center" flexDir="column">
							<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
							<Text fontSize="24px" fontWeight="bold">
								Carregando...
							</Text>
						</Flex>
					)}
				</Flex>
			</Flex>
			<DrawerCreate
				isOpen={createDisclosure.isOpen}
				onClose={createDisclosure.onClose}
				onOpen={createDisclosure.onOpen}
				afterClose={() =>{
					buscarLessons();
					createDisclosure.onClose();
				}}
				bookId={`${bookId}`}
			/>
			<DrawerUpdate
				isOpen={updateDisclosure.isOpen}
				onClose={updateDisclosure.onClose}
				onOpen={updateDisclosure.onOpen}
				afterClose={buscarLessons}
				bookId={`${bookId}`}
				lesson={lessonSelected}
			/>

			<Modal isOpen={modalDisclosure.isOpen} onClose={modalDisclosure.onClose}>
				<ModalOverlay />
				<ModalContent>
				<ModalHeader>Homeworks</ModalHeader>
				<ModalCloseButton />
				<ModalBody padding="20px">
				{
                        
					homeworks.
						sort().
						sort((a, b) => Number(justNumbers(b.nome)) - Number(justNumbers(a.nome))).
						map((home,index)=>
							<Box
								width="100%"
								height="50px"
								borderWidth="1px"
								borderColor="gray.200"
								borderRadius="10px"
								boxShadow="sm"
								bgColor="white"
								cursor="pointer"
								mb="20px"
								onClick={()=>{
									window.open(home.materialUrl,'_blank');
								}}
							>
								<Flex
									height="100%"
									justifyContent="space-around"
									alignItems="center"
								>
									<Flex 
										justifyContent="center"
										alignItems="center"
										gap="10px"
									>
										<BsFileEarmarkMedicalFill size="20px"/>
										<Text fontSize="18px" fontWeight="semibold" >
											{home.nome}
										</Text>
									</Flex>
									
								</Flex>
							</Box>
						)
					}
				</ModalBody>
				
				</ModalContent>
			</Modal>

		</DashLayout>
	);
};
