import React, { useCallback, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	FormLabel,
	Input,
	Select,
	Spinner,
	Stack,
	Text,
	useToast
} from '@chakra-ui/react';
import { ProfessoresGet } from '../Professores';
import { adicionarAluno, listarProfessores } from '../../services/api';
import { formatCpf } from '../../utils/mask';
import useAuth from '../../hooks/useAuth';

type DrawerCreateProps = {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
	afterClose: () => void;
};

export function DrawerCreate({ isOpen, onOpen, onClose, afterClose }: DrawerCreateProps) {
	const toast = useToast();
	const {user, functionId} = useAuth();
	const [ loading, setLoading ] = useState(false);

	const [ nome, setNome ] = useState<string>('');
	const [ sobrenome, setSobrenome ] = useState<string>('');
	const [ cpf, setCpf ] = useState<string>('');
	const [ email, setEmail ] = useState<string>('');
	const [ senha, setSenha ] = useState<string>('');
	const [ professorId, setProfessorId ] = useState<string>('');
	const [ professores, setProfessores ] = useState<ProfessoresGet[]>([]);

	const buscarProfessores = useCallback(
		() => {
			setLoading(true);
			listarProfessores()
				.then((response) => {
					setLoading(false);
					setProfessores(response.data);
				})
				.catch((error) => {
					if (!error.response) return;
					setLoading(false);
					toast({
						title: `Não foi possível listar alunos.`,
						status: 'error',
						isClosable: true
					});
				});
		},
		[ professores ]
	);

	useEffect(() => {
		buscarProfessores();
	}, []);

	const handleSalvar = () => {
		if (!nome || !sobrenome || !cpf || !email || !senha) {
			return toast({
				title: `Todos os campos são obrigatórios!`,
				status: 'error',
				isClosable: true
			});
		}
		setLoading(true);
		adicionarAluno({
			nome,
			sobrenome,
			ativo: true,
			cpf,
			email,
			professorId: user?.userType==="PROFESSOR" ? functionId : professorId,
			senha
		})
			.then(() => {
				setLoading(false);
				toast({
					title: `Aluno(a) adicionado com sucesso.`,
					status: 'success',
					isClosable: true
				});
				resetForm();
				onClose();
				afterClose();
			})
			.catch((error) => {
				setLoading(false);
				if (!error.response) return;
				toast({
					title: `Não foi possível adicionar aluno(a).`,
					status: 'error',
					isClosable: true
				});
				resetForm();
			});
	};

	const resetForm = () => {
		setCpf('');
		setEmail('');
		setNome('');
		setSobrenome('');
		setSenha('');
		setProfessorId('');
	};

	return (
		<Drawer
			isOpen={isOpen}
			placement="right"
			onClose={() => {
				resetForm();
				onClose();
				afterClose();
			}}
		>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader borderBottomWidth="1px">Novo Aluno</DrawerHeader>

				<DrawerBody>
					{loading ? (
						<Flex width="100%" height="90%" justifyContent="center" alignItems="center" flexDir="column">
							<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
							<Text fontSize="24px" fontWeight="bold">
								Carregando...
							</Text>
						</Flex>
					) : (
						<Stack spacing="24px">
							<Box>
								<FormLabel htmlFor="nome">Nome *</FormLabel>
								<Input
									id="nome"
									placeholder="Insira o Nome"
									onChange={(e) => setNome(e.target.value)}
									value={nome}
								/>
							</Box>
							<Box>
								<FormLabel htmlFor="Sobrenome">Sobrenome *</FormLabel>
								<Input
									id="Sobrenome"
									placeholder="Insira o Sobrenome"
									onChange={(e) => setSobrenome(e.target.value)}
									value={sobrenome}
								/>
							</Box>

							<Box>
								<FormLabel htmlFor="cpf">Cpf *</FormLabel>
								<Input
									id="cpf"
									placeholder="Insira o Cpf"
									onChange={(e) => setCpf(formatCpf(e.target.value))}
									value={cpf}
									maxLength={14}
								/>
							</Box>
							<Box>
								<FormLabel htmlFor="Email">Email *</FormLabel>
								<Input
									id="Email"
									placeholder="Insira o Email"
									onChange={(e) => setEmail(e.target.value)}
									type="email"
									value={email}
								/>
							</Box>

							<Box>
								<FormLabel htmlFor="Senha">Senha *</FormLabel>
								<Input
									id="Senha"
									placeholder="******"
									onChange={(e) => setSenha(e.target.value)}
									value={senha}
									type="password"
								/>
							</Box>
							{
								user?.userType === "ADMIN" &&
								<Box>
									<FormLabel htmlFor="professor">Selecione o Professor *</FormLabel>
									<Select
										id="professor"
										defaultValue="selecione"
										onChange={(e) => setProfessorId(e.target.value)}
										value={professorId}
									>
										<option value="" disabled>
											Selecione o Professor
										</option>
										{professores.map((item, index) => {
											return (
												<option
													value={item.id}
													key={index}
													style={{ color: 'black' }}
												>{`${item.nome} ${item.sobrenome}`}</option>
											);
										})}
									</Select>
								</Box>

							}
						</Stack>
					)}
				</DrawerBody>

				<DrawerFooter borderTopWidth="1px">
					<Button variant="outline" mr={3} onClick={onClose} isDisabled={loading}>
						Voltar
					</Button>
					<Button colorScheme="blue" isDisabled={loading} onClick={handleSalvar}>
						Salvar
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
