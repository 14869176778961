import {
	Avatar,
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputLeftElement,
	Select,
	Spinner,
	Stack,
	Switch,
	Table,
	TableCaption,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
	useToast
} from '@chakra-ui/react';
import DashLayout from '../../components/DashLayout';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { BiBookBookmark } from 'react-icons/bi';

import { useCallback, useEffect, useState } from 'react';
import { AlunoUpdateType, disableUser, listarAlunosPorProfessorId, listarProfessores } from '../../services/api';
import { ProfessoresGet } from '../Professores';
import { DrawerCreate } from './DrawerCreate';
import { DrawerUpdate } from './DrawerUpdate';
import useAuth from '../../hooks/useAuth';
import { LiberarMaterial } from './LiberarMaterial';

export interface AlunosType {
	nome: string;
	sobrenome: string;
	cpf: string;
	email: string;
	id: string;
	senha: string;
	professorId: string;
	user: {
		ativo: true;
		avatarUrl: string;
	};
	userId: string;
}

const AlunoStarted: AlunosType = {
	nome: '',
	sobrenome: '',
	cpf: '',
	email: '',
	id: '',
	senha: '',
	user: {
		ativo: true,
		avatarUrl: ''
	},
	userId: '',
	professorId: ''
};

export const Alunos = () => {
	const toast = useToast();
	const createDisclosure = useDisclosure();
	const updateDisclosure = useDisclosure();
	const materialDisclosure = useDisclosure();

	const {user, functionId} = useAuth();
	const [ alunos, setAlunos ] = useState<AlunosType[]>([]);
	const [ alunosMaterial, setAlunosMaterial ] = useState<AlunosType>(AlunoStarted);
	const [ alunoUpdate, setAlunoUpdate ] = useState<AlunoUpdateType>(AlunoStarted);
	const [ professores, setProfessores ] = useState<ProfessoresGet[]>([]);
	const [ professorId, setProfessorId ] = useState<string>('');
	const [ search, setSearch ] = useState<string>('');
	const [ loading, setLoading ] = useState<boolean>(true);
	const [ updateData, setUpdateData ] = useState<boolean>(true);

	const buscarAlunos = (id: string) => {
		setLoading(true);
		listarAlunosPorProfessorId(id)
			.then((response) => {
				setLoading(false);
				setAlunos(response.data);
				setUpdateData(false);
			})
			.catch((error) => {
				if (!error.response) return;
				setLoading(false);
				toast({
					title: `Não foi possível listar alunos.`,
					status: 'error',
					isClosable: true
				});
			});
	};

	const buscarProfessores = useCallback(
		() => {
			setLoading(true);
			listarProfessores()
				.then((response) => {
					setLoading(false);
					setProfessores(response.data);
				})
				.catch((error) => {
					if (!error.response) return;
					setLoading(false);
					toast({
						title: `Não foi possível listar alunos.`,
						status: 'error',
						isClosable: true
					});
				});
		},
		[ alunos ]
	);

	useEffect(() => {
		if(user?.userType === "PROFESSOR"){
			buscarAlunos(functionId);
		}else{
			buscarProfessores();
		}
	}, []);

	const reload = () => {
		if(user?.userType === "PROFESSOR"){
			buscarAlunos(functionId);
		}else{
			buscarAlunos(professorId);
		}
	};

	const deleteUser = (id: string, value: boolean) => {
		const result = value ? 'habilitado' : 'desabilitado';
		const action = value ? 'habilitar' : 'desabilitar';
		setLoading(true);
		disableUser(id, value)
			.then(() => {
				setUpdateData(true);
				setLoading(false);
				toast({
					title: `Aluno(a) ${result}`,
					status: 'success',
					isClosable: true
				});
			})
			.catch((error) => {
				if (!error.response) return;
				setLoading(false);
				toast({
					title: `Não foi possível ${action} aluno.`,
					status: 'error',
					isClosable: true
				});
			});
	};

	const handleSelectProfessor = (id: string) => {
		buscarAlunos(id);
		setProfessorId(id);
	};

	return (
		<DashLayout>
			<Flex width="100%" height="100%" flexDir="column">
				<Flex width="100%" backgroundColor="white" height="130px" padding="20px" flexDir="column">
					<Text fontSize="24px" marginBottom="10px">
						Alunos
					</Text>
					<Flex gap="20px">
						{ user?.userType === "ADMIN" &&
							<Select
								onChange={(e) => {
									handleSelectProfessor(e.target.value);
								}}
								border="none"
								bgColor="gray.300"
								width="35%"
							>
								<option value="" style={{ color: 'black' }} disabled>
									Selecione o Professor
								</option>;
								{professores.map((item, index) => {
									return (
										<option
											value={item.id}
											key={index}
											style={{ color: 'black' }}
										>{`${item.nome} ${item.sobrenome}`}</option>
									);
								})}
							</Select>
						}
						<InputGroup gap={'20px'}>
							<InputLeftElement pointerEvents="none" color={'gray.600'}>
								<FaSearch />
							</InputLeftElement>
							<Input
								type="tel"
								placeholder="Buscar alunos..."
								onChange={(e) => setSearch(e.target.value)}
								value={search}
								border="none"
								bgColor="gray.300"
							/>
							<Button
								leftIcon={<FaPlus />}
								colorScheme="blue"
								variant="solid"
								onClick={() => createDisclosure.onOpen()}
							>
								Novo
							</Button>
						</InputGroup>
					</Flex>
				</Flex>
				<Flex width="100%" bgColor="white" minH="100%">
					{!loading ? (
						<Box overflowY="auto" overflowX="auto" width={{base:"90vw", md:"100vw", sm:"100vw"}} minH="100%" paddingBottom="200px">
							<Table variant="simple">
								<TableCaption>Alunos</TableCaption>
								<Thead position="sticky" top={0} bgColor="white" zIndex="999">
									<Tr>
										<Th>Nome</Th>
										<Th>Cpf</Th>
										<Th>Email</Th>
										<Th>Status</Th>
										<Th>Ações</Th>
									</Tr>
								</Thead>
								<Tbody>
									{alunos && alunos.map((aluno, index) => 
										{
											if (
												Object.values(aluno)
													.map((variavel) => variavel)
													.reduce((a, b) => (b = a + ' ' + b))
													.toLowerCase()
													.includes(search.toLowerCase())
											)
											return (<Tr _hover={{ bgColor: 'gray.100', cursor: 'pointer' }} key={index}>
												<Td
													onClick={() => {
														setAlunoUpdate(aluno);
														updateDisclosure.onOpen();
													}}
												>
													<Flex gap={'10px'} alignItems={'center'}>
														<Avatar
															size="sm"
															name={aluno.nome + ' ' + aluno.sobrenome}
															src={aluno.user.avatarUrl}
														/>
														{aluno.nome + ' ' + aluno.sobrenome}
													</Flex>
												</Td>
												<Td
													onClick={() => {
														setAlunoUpdate(aluno);
														updateDisclosure.onOpen();
													}}
												>
													{aluno.cpf}
												</Td>
												<Td
													onClick={() => {
														setAlunoUpdate(aluno);
														updateDisclosure.onOpen();
													}}
												>
													{aluno.email}
												</Td>
												<Td>
													<Stack align="center" direction="row">
														<Switch
															size="lg"
															isChecked={aluno.user.ativo}
															onChange={() => deleteUser(aluno.userId, !aluno.user.ativo)}
														/>
													</Stack>
												</Td>
												<Td>
												<Button
													leftIcon={<BiBookBookmark />}
													colorScheme="blue"
													variant="solid"
													onClick={() => {
														setAlunosMaterial(aluno)
														setAlunoUpdate(aluno);
														materialDisclosure.onOpen()
													}}
												>
													Materiais
												</Button>
												</Td>
											</Tr>)
										}
									)}
								</Tbody>
							</Table>
						</Box>
					) : (
						<Flex width="100%" height="90%" justifyContent="center" alignItems="center" flexDir="column">
							<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
							<Text fontSize="24px" fontWeight="bold">
								Carregando...
							</Text>
						</Flex>
					)}
				</Flex>
			</Flex>
			<DrawerCreate
				isOpen={createDisclosure.isOpen}
				onOpen={createDisclosure.onOpen}
				onClose={createDisclosure.onClose}
				afterClose={reload}
			/>
			<DrawerUpdate
				isOpen={updateDisclosure.isOpen}
				onOpen={updateDisclosure.onOpen}
				onClose={updateDisclosure.onClose}
				afterClose={reload}
				aluno={alunoUpdate}
			/>
			<LiberarMaterial
				isOpen={materialDisclosure.isOpen}
				onOpen={materialDisclosure.onOpen}
				onClose={materialDisclosure.onClose}
				aluno={alunosMaterial}
				afterClose={reload}
			/>
		</DashLayout>
	);
};
