import {
	Badge,
	Button,
	Flex,
	Input,
	InputGroup,
	InputLeftElement,
	Radio,
	RadioGroup,
	Spinner,
	Stack,
	Text,
	useDisclosure,
	useToast
} from '@chakra-ui/react';
import DashLayout from '../../components/DashLayout';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { useCallback, useEffect, useState } from 'react';
import { CreateBookPost, deleteBook, listarBooks } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import ButtonOptions from '../../components/ButtonOptions';
import { DrawerCreate } from './DrawerCreate';
import { DrawerUpdate } from './DrawerUpdate';
import { VscOpenPreview } from 'react-icons/vsc';

export type BookGet = {
	id: string;
	nome: string;
	descricao: string;
	capa: string;
	idioma: string;
	nivel: string;
};

const bookDefault: BookGet = {
	id: '',
	nome: '',
	descricao: '',
	capa: '',
	idioma: '',
	nivel: ''
};

export const Books = () => {
	const [ books, setBooks ] = useState<BookGet[]>([]);
	const [ booksViwed, setBooksViwed ] = useState<BookGet[]>([]);
	const [ loading, setLoading ] = useState(true);
	const [ search, setSearch ] = useState<string>('');
	const [ filter, setFilter ] = useState<string>('');
	const [ bookSelected, setBookSelected ] = useState<BookGet>(bookDefault);

	const toast = useToast();
	const navigate = useNavigate();
	const createDisclosure = useDisclosure();
	const updateDisclosure = useDisclosure();

	const buscarBooks = useCallback(
		() => {
			setLoading(true);
			listarBooks()
				.then((response) => {
					setBooks(response.data);
					setBooksViwed(response.data);
					setLoading(false);
					setFilter("Todos");
				})
				.catch((err) => {
					toast({
						title: `Não foi possível carregar o livro${err}`,
						status: 'error',
						isClosable: true
					});
				});
		},
		[ books ]
	);

	useEffect(() => {
		buscarBooks();
	}, []);

	const handleBookEdit = (book: BookGet) => {
		console.log(book);
		setBookSelected(book);
		updateDisclosure.onOpen();
	};

	function openTab(bookId: string) {
		window.open(`/dashboard/materiais/${bookId}`, '', 'popup,width=1080,height=660, left=300, top=500');
	}

	function filterBooks(idioma:string){
		var filtered:BookGet[] = [];
		if(idioma === "Todos")	return setBooksViwed(books);
		filtered = books.filter((b) => b.idioma === idioma);
		setBooksViwed(filtered);
	}

	return (
		<DashLayout>
			<Flex width="100%" height="100%" flexDir="column">
				<Flex width="100%" backgroundColor="white" height="160px" padding="20px" flexDir="column">
					<Text fontSize="24px" marginBottom="10px">
						Books
					</Text>
					<Flex gap="20px">
						<InputGroup gap={'20px'}>
							<InputLeftElement pointerEvents="none" color={'gray.600'}>
								<FaSearch />
							</InputLeftElement>
							<Input
								type="tel"
								placeholder="Buscar books..."
								onChange={(e) => setSearch(e.target.value)}
								value={search}
								border="none"
								bgColor="gray.300"
							/>
							<Button
								leftIcon={<FaPlus />}
								colorScheme="blue"
								variant="solid"
								onClick={() => createDisclosure.onOpen()}
							>
								Novo
							</Button>
						</InputGroup>
					</Flex>
					<RadioGroup onChange={(e)=>{
							setFilter(e);
							filterBooks(e);
						}} 
					value={filter}>
						<Stack direction="row" paddingTop="15px" justifyContent="center">
							<Radio value="Todos">Todos</Radio>
							<Radio value="Inglês">Inglês</Radio>
							<Radio value="Português">Português</Radio>
							<Radio value="Espanhol">Espanhol</Radio>
							<Radio value="Italiano">Italiano</Radio>
							<Radio value="Francês">Francês</Radio>
						</Stack>
					</RadioGroup>
				</Flex>
				<Flex
					height="90%"
					gap="20px"
					justifyContent="center"
					paddingTop="20px"
					flexWrap="wrap"
					overflowY="auto"
				>
					{!loading ? (
						<>
							{
							booksViwed.length > 0 ? 
							booksViwed.map((book, index) => {
							if (
								Object.values(book)
									.map((variavel) => variavel)
									.reduce((a, b) => (b = a + ' ' + b))
									.toLowerCase()
									.includes(search.toLowerCase())
							)
								return (
									<Flex
										width="250px"
										height="210px"
										bgColor="blue.700"
										borderRadius="25px"
										alignItems="center"
										justifyContent="center"
										flexDir="column"
										gap="10px"
										borderWidth="3px"
										borderColor="white"
										boxShadow="lg"
									>
										<Flex
											height="130px"
											bgImage={book.capa}
											minWidth="230px"
											maxWidth="230px"
											bgPosition="center"
											bgSize="cover"
											borderRadius="10px"
										/>
										<Flex alignItems="center" gap="10px" justifyContent="center">
											<Flex flexDir="column">
												<Text color="white" fontSize="20px" fontWeight="800" textAlign="center">
													{book.nome}
												</Text>
											</Flex>
											<Flex>
												<ButtonOptions
													isBook={true}
													onView={() => {
														openTab(book.id);
													}}
													onEdit={() => handleBookEdit(book)}
													onOpen={() => navigate(`/dashboard/${book.id}/lessons`)}
												/>
											</Flex>
										</Flex>
									</Flex>
								);
						})
						:
							<Flex width="100%"  justifyContent="center" alignItems="center">
								<VscOpenPreview size="24px"/>
								<Text fontSize="24px">Nenhum book encontrado!</Text>
							</Flex>
						}
						</>
					) : (
						<Flex width="100%" height="90%" justifyContent="center" alignItems="center" flexDir="column">
							<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
							<Text fontSize="24px" fontWeight="bold">
								Carregando...
							</Text>
						</Flex>
					)}
				</Flex>
			</Flex>
			<DrawerCreate
				isOpen={createDisclosure.isOpen}
				onClose={createDisclosure.onClose}
				onOpen={createDisclosure.onOpen}
				afterClose={() => buscarBooks()}
			/>
			<DrawerUpdate
				isOpen={updateDisclosure.isOpen}
				onClose={updateDisclosure.onClose}
				onOpen={updateDisclosure.onOpen}
				afterClose={buscarBooks}
				bookUpdate={bookSelected}
			/>
		</DashLayout>
	);
};
