import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverBody,
	PopoverArrow,
	IconButton,
	Button,
	Stack,
	Flex
} from '@chakra-ui/react';

import { BsThreeDotsVertical } from 'react-icons/bs';
import { BiEdit, BiWindowOpen } from 'react-icons/bi';
import { TfiViewGrid } from 'react-icons/tfi';
import useAuth from '../../hooks/useAuth';

type ButtonOptionsProps = {
	onOpen: () => void;
	onView: () => void;
	onEdit: () => void;
	openHomeworks?:() => void;
	isBook:boolean;
};
export default function ButtonOptions({ onView, onEdit, onOpen, isBook, openHomeworks }: ButtonOptionsProps) {
	const {user} = useAuth();
	return (
		<Flex justifyContent="center">
			<Popover placement="bottom" isLazy>
				<PopoverTrigger>
					<IconButton
						aria-label="More server options"
						icon={<BsThreeDotsVertical />}
						variant="solid"
						rounded="md"
						colorScheme="blue.800"
						_hover={{
							bgColor: 'blue.600',
							cursor: 'pointer'
						}}
					/>
				</PopoverTrigger>
				<PopoverContent w="fit-content" _focus={{ boxShadow: 'none' }}>
					<PopoverArrow />
					<PopoverBody>
						<Stack>
							{
								!isBook &&
								<>
									<Button
										w="120px"
										variant="ghost"
										rightIcon={<TfiViewGrid />}
										justifyContent="space-between"
										fontWeight="normal"
										fontSize="sm"
										onClick={onView}
									>
										Visualizar
									</Button>
									<Button
										w="120px"
										variant="ghost"
										rightIcon={<BiWindowOpen />}
										justifyContent="space-between"
										fontWeight="normal"
										fontSize="sm"
										onClick={openHomeworks}
									>
										Homeworks
									</Button>
								</>
							}
							
							{ isBook && <Button
								w="120px"
								variant="ghost"
								rightIcon={<BiWindowOpen />}
								justifyContent="space-between"
								fontWeight="normal"
								fontSize="sm"
								onClick={onOpen}
							>
								Abrir
							</Button>}
							{
								user?.userType=== "ADMIN" &&
								<>
									<Button
										w="120px"
										variant="ghost"
										rightIcon={<BiEdit />}
										justifyContent="space-between"
										fontWeight="normal"
										fontSize="sm"
										onClick={onEdit}
									>
										Editar
									</Button>
								</>
							}
							
						</Stack>
					</PopoverBody>
				</PopoverContent>
			</Popover>
		</Flex>
	);
}
