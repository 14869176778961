import { Flex, Text } from '@chakra-ui/react';

type FormErrorProps = {
	text: string;
};

export const FormError = ({ text }: FormErrorProps) => {
	return (
		<Flex padding="5px">
			<Text color="red.400" fontSize="14px">
				{text}
			</Text>
		</Flex>
	);
};
