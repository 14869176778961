import React from 'react';
import './App.css';
import AuthContextProvider from './contexts/AuthContext';
import AplicationRoutes from './routes/Index.routes';

function App() {
	return (
		<AuthContextProvider>
			<AplicationRoutes />
		</AuthContextProvider>
	);
}
export default App;
