import React, { useCallback, useEffect, useState } from 'react';
import {
	Avatar,
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	FormLabel,
	Input,
	Select,
	Spinner,
	Stack,
	Text,
	useToast
} from '@chakra-ui/react';
import { ProfessoresGet } from '../Professores';
import { adicionarAula, listarAlunos, listarAlunosPorProfessorId, listarBooks } from '../../services/api';
import { formatCpf } from '../../utils/mask';
import { AlunosType } from '.';
import { BookGet } from '../Books';
import useAuth from '../../hooks/useAuth';

type DrawerCreateProps = {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
	afterClose: () => void;
	professor: ProfessoresGet;
};
const alunosDefault: AlunosType[] = [
	{
		cpf: '',
		email: '',
		id: '',
		nome: '',
		sobrenome: '',
		user: {
			ativo: true,
			avatarUrl: ''
		},
		userId: ''
	}
];
export function DrawerCreate({ isOpen, onOpen, onClose, afterClose, professor }: DrawerCreateProps) {
	const toast = useToast();

	const [ loading, setLoading ] = useState(false);

	const [ titulo, setTitulo ] = useState<string>('');
	const [ data, setData ] = useState<string>('');
	const [ alunos, setAlunos ] = useState<AlunosType[]>(alunosDefault);
	const [ aluno, setAluno ] = useState<AlunosType>();
	const [ alunoId, setAlunoId ] = useState<string>('');
	const [ materialId, setMaterialId ] = useState<string>('');
	const [ books, setBooks ] = useState<BookGet[]>([]);
	const {user, functionId} = useAuth();

	const buscarAlunos = useCallback(
		() => {
			setLoading(true);
			listarAlunosPorProfessorId(functionId)
				.then((response) => {
					setLoading(false);
					setAlunos(response.data);
				})
				.catch((error) => {
					if (!error.response) return;
					setLoading(false);
					toast({
						title: `Não foi possível listar alunos.`,
						status: 'error',
						isClosable: true
					});
				});
		},
		[ alunos ]
	);

	const buscarMateriais = useCallback(
		() => {
			listarBooks()
				.then((response) => {
					setBooks(response.data);
				})
				.catch((error) => {
					if (!error.response) return;
					toast({
						title: `Não foi possível listar os materiais.`,
						status: 'error',
						isClosable: true
					});
				});
		},
		[ books ]
	);

	useEffect(
		() => {
			buscarAlunos();
			buscarMateriais();
		},
		[ isOpen, professor ]
	);

	const handleSalvar = () => {
		if (!alunoId || !data || !titulo || !materialId) {
			toast({
				title: `Todos os campos são obrigatórios!`,
				status: 'error',
				isClosable: true
			});
		} else {
			setLoading(true);
			adicionarAula({
				data,
				alunoId: alunoId,
				professorId: functionId,
				status: 'AGENDADA',
				titulo,
				materialId
			})
				.then(() => {
					setLoading(false);
					toast({
						title: `Aula adicionada com sucesso.`,
						status: 'success',
						isClosable: true
					});
					onClose();
					afterClose();
				})
				.catch((error) => {
					setLoading(false);
					if (!error.response) return;
					toast({
						title: `Não foi possível adicionar nova aula.`,
						status: 'error',
						isClosable: true
					});
				});
		}
	};

	const resetForm = () => {
		setTitulo('');
		setData('');
		setAlunos(alunosDefault);
	};

	return (
		<Drawer
			isOpen={isOpen}
			placement="right"
			onClose={() => {
				resetForm();
				onClose();
				afterClose();
			}}
		>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader borderBottomWidth="1px">Nova Aula</DrawerHeader>

				<DrawerBody>
					{loading ? (
						<Flex width="100%" height="90%" justifyContent="center" alignItems="center" flexDir="column">
							<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
							<Text fontSize="24px" fontWeight="bold">
								Carregando...
							</Text>
						</Flex>
					) : (
						<Stack spacing="24px">
							<Box>
								<FormLabel>Professor</FormLabel>
								<Flex
									gap={'10px'}
									alignItems="center"
									justifyContent="center"
									paddingTop="10px"
									paddingBottom="10px"
									borderWidth="1px"
									borderColor="gray.200"
									borderRadius="10px"
								>
									<Avatar size="sm" name={user?.name} src={user?.avatar} />
									{`${user?.name}`}
								</Flex>
							</Box>
							<Box>
								<FormLabel>Título *</FormLabel>
								<Input
									placeholder="Insira o Título"
									onChange={(e) => setTitulo(e.target.value)}
									value={titulo}
								/>
							</Box>
							<Box>
								<FormLabel>Data *</FormLabel>
								<Input type="datetime-local" onChange={(e) => setData(e.target.value)} value={data} />
							</Box>
							<Box>
								<FormLabel htmlFor="aluno">Selecione o Aluno *</FormLabel>
								<Select
									defaultValue="selecione"
									onChange={(e) => {
										setAlunoId(e.target.value);
										setAluno(alunos.find((aluno) => aluno.id == alunoId));
									}}
									value={alunoId}
								>
									<option value="" disabled>
										Selecione o Aluno
									</option>
									{alunos &&
										alunos.map((item, index) => {
											return (
												<option
													value={item.id}
													key={index}
													style={{ color: 'black' }}
												>{`${item.nome} ${item.sobrenome}`}</option>
											);
										})}
								</Select>
							</Box>
							<Box>
								<FormLabel htmlFor="aluno">Selecione o Material *</FormLabel>
								<Select
									defaultValue="selecione"
									onChange={(e) => {
										setMaterialId(e.target.value);
									}}
									value={materialId}
								>
									<option value="" disabled>
										Selecione o Material
									</option>
									{books &&
										books.map((item, index) => {
											return (
												<option
													value={item.id}
													key={index}
													style={{ color: 'black' }}
												>{`${item.nome}`}</option>
											);
										})}
								</Select>
							</Box>
						</Stack>
					)}
				</DrawerBody>

				<DrawerFooter borderTopWidth="1px">
					<Button variant="outline" mr={3} onClick={onClose} isDisabled={loading}>
						Voltar
					</Button>
					<Button colorScheme="blue" isDisabled={loading} onClick={handleSalvar}>
						Salvar
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
