import { buscarAlunoPorId, buscarBookPorId, updateStatusAula, buscarAulaPorID } from '../../services/api';
import { useEffect, useRef, useState } from 'react';
import {
	Button,
	Flex,
	Spinner,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useToast
} from '@chakra-ui/react';
import { MdOutlineLibraryBooks } from 'react-icons/md';
import { useParams } from 'react-router-dom';
import { Document, Page } from 'react-pdf';

export type Lesson = {
	id: string;
	nome: string;
	canvaUrl: string;
	bookId: string;
	thumbnail: string;
};

export type Aula = {
	id: string;
	titulo: string;
	alunoId: string;
	materialId: string;
	professorId: string;
	status: string;
};

export type Book = {
	id: string;
	nome: string;
	descricao: string;
	capa: string;
	idioma: string;
	nivel: string;
	lessons: Lesson[];
};

export default function MateriaisId() {
	const toast = useToast();
	const [ denied, setDenied ] = useState(false);
	const [ loading, setLoading ] = useState(true);
	const [ documentloading, setDocumentLoading ] = useState(false);
	const [ countdown, setCountdown ] = useState(4800);
	const [ nomeAluno, setNomeAluno ] = useState('');

	const [ aula, setAula ] = useState<Aula>();
	const [ aulaStatus, setAulaStatus ] = useState('');
	const [ aulaBook, setBook ] = useState<Book>();
	const [ lessons, setLessons ] = useState<Lesson[]>([]);
	const [ lesson, setLesson ] = useState<Lesson>();
	const [ count, setCount ] = useState(0);
	const [ numPages, setNumPages ] = useState(0);
	const [ currentPage, setCurrentPage ] = useState(1);

	const { aulaId } = useParams();

	useEffect(() => {
		buscarAulaPorID(aulaId)
			.then((aulaData) => {
				setAula(aulaData.data);
				if (aulaData.data.status === 'INICIADA') {
					const timers = aulaData.data.aulaHistorico.sort((a: any, b: any) => a.timer - b.timer);
					setCountdown(timers[0].timer);
				}
				buscarAlunoPorId(aulaData.data.alunoId).then((aluno) => {
					setNomeAluno(aluno.data.nome + ' ' + aluno.data.sobrenome);
				});
				buscarBookPorId(aulaData.data.materialId)
					.then((response) => {
						setLoading(false);
						const book: Book = response.data;
						setBook(book);
						setLessons(book.lessons);
					})
					.catch((err) => {
						toast({
							title: `${err}`,
							status: 'error',
							isClosable: true
						});
					});
			})
			.catch((err) => {
				toast({
					title: `${err}`,
					status: 'error',
					isClosable: true
				});
			});
	}, []);

	useEffect(
		() => {
			const intervalId = setInterval(() => {
				if (countdown < 0) {
					setCountdown(0);
					setDenied(true);
					updateStatusAula(`${aulaId}`, 'CONCLUIDA', {
						info: 'ENCERRANDO AULA',
						timer: countdown
					});
					window.close();
				} else {
					setCountdown((countdown) => countdown - 1);
				}
			}, 1000);

			return () => clearInterval(intervalId);
		},
		[ countdown ]
	);

	const handleSetLesson = (id: string) => {
		setDocumentLoading(true);
		const lessonFilter = lessons.find((item) => item.id === id);
		setLesson(lessonFilter);
	};

	const hours = Math.floor(countdown / 3600);
	const minutes = Math.floor((countdown % 3600) / 60);
	const seconds = countdown % 60;

	const handlePrev = () => {
		if (currentPage > 1) setCurrentPage(currentPage - 1);
	};

	const handleNext = () => {
		if (currentPage < numPages) setCurrentPage(currentPage + 1);
	};

	useEffect(
		() => {
			setCount(count + 1);
			updateStatusAula(`${aulaId}`, 'INICIADA', {
				info: 'Update Timer',
				timer: countdown
			});
			if (count > 2) {
				//toast({
				//	title: `Information`,
				//	description:
				//		'You can access this material by contacting Windfall management. Copyrighted material!',
				//	status: 'info',
				//	isClosable: true,
				//	position: 'top-left'
				//});
				setCount(0);
			}
		},
		[ minutes ]
	);
	//@ts-ignore
	function onDocumentLoadSuccess({ numPages }) {
		setNumPages(numPages);
		setTimeout(()=>{
			setDocumentLoading(false);
		}, 2000);
	}
	useEffect(() => {
		window.addEventListener('keydown', function(event) {
			const keys = [ 91, 16, 17, 18, 44 ];
			if (keys.includes(event.keyCode) || event.keyCode === 91) {
				setDenied(true);
			}
		});

		window.addEventListener('keyup', function(event) {
			const keys = [ 91, 16, 17, 18, 44 ];
			if (keys.includes(event.keyCode) || event.keyCode === 91) {
				setDenied(true);
			}
		});
	  
		window.addEventListener('keyup', function(event) {
			navigator.clipboard.writeText("Conteúdo protegido por direitos autorais.");
		});
	}, []);

	return (
		<div autoFocus>
			{loading ? (
				<Flex
					flexDir="column"
					backgroundColor="gray.800"
					alignItems="center"
					justifyContent="center"
					width="100%"
					height="100vh"
					gap="20px"
				>
					<Text fontSize="24px" fontWeight="bold" color="#ddd">
						Iniciando...
					</Text>
					<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
				</Flex>
			) : (
				<Flex flexDir="column" backgroundColor="gray.800">
					<Flex>
						<Flex
							alignItems={'center'}
							gap={'50px'}
							bgColor={'gray.800'}
							width={'100%'}
							height={'60px'}
							padding="8px"
							position="fixed"
							bottom="0"
							zIndex={999}
						>
							<Button
								aria-label="Toggle Color Mode"
								onClick={() => {
									setLesson(undefined);
								}}
								_focus={{ boxShadow: 'none' }}
								w="fit-content"
							>
								<MdOutlineLibraryBooks />
							</Button>

							<Text fontWeight={900} color={'white'}>
								{countdown === 0 ? (
									'Acabou o tempo'
								) : (
									`${hours.toString().padStart(2, '0')}:${minutes
										.toString()
										.padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
								)}
							</Text>
							<Text fontWeight={900} color={'white'}>
								{nomeAluno}
							</Text>
							<Button
								aria-label="Toggle Color Mode"
								onClick={handlePrev}
								_focus={{ boxShadow: 'none' }}
								w="fit-content"
								colorScheme="gray"
								zIndex={999}
								id="btn"
							>
								{'<'}
							</Button>
							<Text fontWeight={900} color={'white'}>
								{`${currentPage}/${numPages}`}
							</Text>
							<Button
								aria-label="Toggle Color Mode"
								onClick={handleNext}
								_focus={{ boxShadow: 'none' }}
								w="fit-content"
								colorScheme="gray"
								zIndex={999}
								id="btn"
							>
								{'>'}
							</Button>
						</Flex>
					</Flex>
					<Flex overflow="hidden" background="#112233">
						<div className="wrap">
							<Document
								file={lesson?.canvaUrl}
								onLoadSuccess={onDocumentLoadSuccess}
								className="pdf"
								onContextMenu={(e) => e.preventDefault()}
							>
								<Page pageNumber={currentPage} />
							</Document>
						</div>
					</Flex>
					<Flex
						position="absolute"
						height="100vh"
						width="100vw"
						flexDir="column"
						alignItems="center"
						justifyContent="center"
						background="#112233"
						opacity="0.95"
						backdropFilter="blur(10px)"
						visibility={!denied ? 'hidden' : 'visible'}
						zIndex={999}
					>
						<h1
							style={{
								fontSize: '3rem',
								padding: '10px',
								color: 'white',
								fontWeight: '900'
							}}
						>
							Cuidado!
						</h1>
						<h5
							style={{
								fontSize: '1rem',
								textAlign: 'center',
								width: '60%',
								color: 'white',
								marginBottom: '20px'
							}}
						>
							Não utilize este material de forma inapropriada ou sem consentimento da coordenação da
							Windfall.
						</h5>
						<Text color="white" marginBottom="20px" fontSize="12px">
							Conteúdo protegido por direitos autorais.
						</Text>
						<Button
							colorScheme="blue"
							onClick={() => {
								setDenied(false);
							}}
						>
							VOLTAR
						</Button>
					</Flex>

					<Flex
						position="absolute"
						height="100vh"
						width="100vw"
						flexDir="column"
						alignItems="center"
						justifyContent="center"
						background="#112233"
						opacity="1"
						backdropFilter="blur(10px)"
						visibility={!documentloading ? 'hidden' : 'visible'}
						zIndex={999}
					>
						<Text fontSize="24px" fontWeight="bold" color="#ddd">
						Carregando...
						</Text>
						<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
					</Flex>
					<Flex
						position="absolute"
						height="100vh"
						width="100vw"
						flexDir="column"
						alignItems="center"
						justifyContent="center"
						background="#112233"
						opacity="0.95"
						backdropFilter="blur(10px)"
						visibility={lesson ? 'hidden' : 'visible'}
						zIndex={999}
					>
						<Flex
							position="absolute"
							height="100vh"
							width="100vw"
							background="#112233"
							backdropFilter="blur(10px)"
							opacity="1"
							justifyContent="center"
							alignItems="center"
							flexWrap="wrap"
							padding="50px"
							paddingBottom="80px"
							overflowY="scroll"
							zIndex={889}
							visibility={lesson ? 'hidden' : 'visible'}
						>
							<Flex justifyContent="center" alignItems="center" flexWrap="wrap" gap="10px">
								{lessons
									.sort((a, b) => {
										if (Number(a.nome.split(' ')[1]) < Number(b.nome.split(' ')[1])) return -1;
										if (Number(a.nome.split(' ')[1]) > Number(b.nome.split(' ')[1])) return 1;
										return 0;
									})
									.map((lesson, index) => (
										<Flex
											position="relative"
											width="260px"
											height="100px"
											bgColor="blue.700"
											borderRadius="15px"
											alignItems="center"
											padding="10px"
											gap="10px"
											key={index}
											boxShadow="lg"
											_hover={{
												cursor: 'pointer',
												bgColor: 'blue.600'
											}}
											onClick={() => {
												handleSetLesson(lesson.id);
											}}
										>
											<Flex
												height="80px"
												bgImage={lesson.thumbnail}
												minWidth="80px"
												maxWidth="80px"
												bgPosition="center"
												bgSize="cover"
												borderRadius="15px"
											/>
											<Flex flexDir="column">
												<Flex alignItems="center" gap="10px">
													<Text
														color="white"
														fontSize="20px"
														fontWeight="800"
														textAlign="center"
													>
														{lesson.nome}
													</Text>
												</Flex>
											</Flex>
										</Flex>
									))}
							</Flex>
						</Flex>
					</Flex>
				</Flex>
			)}
		</div>
	);
}
