import { Avatar, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Grid, GridItem, IconButton, Img, Show, Text, useDisclosure } from '@chakra-ui/react';
import { FaBook, FaChalkboardTeacher, FaHome, FaUserGraduate, FaUserTie } from 'react-icons/fa';
import { TbLogout } from 'react-icons/tb';

import useAuth from '../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { ModalPerfil } from '../../components/ModalPerfil';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';

type DashLayoutProps = {
	children?: JSX.Element | JSX.Element[];
};

const NavItems = {
	"ADMIN":[
		{ nome: 'Dashboard', icon: <FaHome color="white" />, route: '/dashboard' },
		{ nome: 'Alunos', icon: <FaUserGraduate color="white" />, route: '/dashboard/alunos' },
		{ nome: 'Professores', icon: <FaUserTie color="white" />, route: '/dashboard/professores' },
		{ nome: 'Books', icon: <FaBook color="white" />, route: '/dashboard/books' },
		{ nome: 'Aulas', icon: <FaChalkboardTeacher color="white" />, route: '/dashboard/aulas' }
	],
	"PROFESSOR":[
		{ nome: 'Dashboard', icon: <FaHome color="white" />, route: '/dashboard' },
		{ nome: 'Alunos', icon: <FaUserGraduate color="white" />, route: '/dashboard/alunos' },
		{ nome: 'Books', icon: <FaBook color="white" />, route: '/dashboard/books' },
		{ nome: 'Aulas', icon: <FaChalkboardTeacher color="white" />, route: '/dashboard/aulas' }
	],
};

const DashLayout = ({ children }: DashLayoutProps) => {

	const {isOpen, onClose, onOpen} = useDisclosure();
	const menuDisclosure = useDisclosure();
	const { logout, user } = useAuth();
	const navigate = useNavigate();

	const userMenu = user?.userType === "ADMIN" ? "ADMIN" : "PROFESSOR"
	
	return (
		<Grid
			templateAreas={{
				base:`"header" 
						"main"`,
				xl:`"header header" 
				"nav main"`,
				lg:`"header" "main"`,
				sm:`"header" "main"`,
				md:`"header" "main"`,
			}}
			gridTemplateRows={{
				base:'80px',
				xl:'80px 1fr',
				lg:'80px',
				sm:'80px',
				md:'80px',
			}}
			gridTemplateColumns={{
				base:'1fr',
				xl:'230px 1fr',
				lg:'1fr',
				sm:'1fr',
				md:'1fr',
			}}
			bgColor="gray.300"
			h="100vh"
			gap="1"
			fontWeight="bold"
			overflow="hidden"
		>
			<GridItem pl="2" bg="blue.700" area={'header'} overflow="hidden">
				<Flex width="100%" height="100%" justifyContent="space-between" padding="10px" >
					<Flex alignItems="center" gap="10px">
						<Img
							src={
								'https://firebasestorage.googleapis.com/v0/b/projetcs-storage.appspot.com/o/windfall%2FWhatsApp%20Image%202023-03-29%20at%2014.04.46.jpeg?alt=media&token=1095f376-9566-4936-99c3-5eb9e3c1a541'
							}
							width="60px"
							height="60px"
							borderRadius="10px"
							boxShadow="lg"
						/>
						<Text fontSize={{ 
                base:"18px" ,
                xl:"18px 18px" ,
                lg:"eader" ,
                sm:"18px" ,
                md:"18px",  
              }} fontFamily="Poppins" fontWeight="extrabold" color="white">
							Windfall Language Institute
						</Text>
					</Flex>
					<Show above='lg'>
						<Flex
							gap="10px"
							alignItems="center"
							padding="10px"
							_hover={{
								bgColor: 'blue.900',
								borderRadius: '10px',
								cursor: 'pointer'
							}}
							onClick={()=>onOpen()}
						>
							<Avatar size={"md"} src={user?.avatar} />
							<Flex flexDir="column">
								<Text fontSize="16px" fontFamily="Poppins" fontWeight="regular" color="white">
									{user?.name}
								</Text>
								<Text fontSize="12px" fontFamily="Poppins" fontWeight="thin" color="white">
									{user?.userType}
								</Text>
							</Flex>
						</Flex>		
					</Show>
					<Show below='lg'>
						<Flex
							alignItems="center"
							ml="100px"
						>
							<IconButton
								onClick={()=>menuDisclosure.onOpen()}
								icon={
								isOpen ? <CloseIcon w={3} h={3} 
								color={"#DDD"}
								/> 
								: <HamburgerIcon w={5} h={5} 
									color={"#DDD"}
									/>
								}
								variant={"ghost"}
								aria-label={"Toggle Navigation"}
							/>
					</Flex>
					</Show>
				</Flex>
			</GridItem>
			<Show above='lg'>
				<GridItem  bg="blue.700" area={'nav'} padding="15px"overflow="hidden" >
					<Flex flexDir="column" height="100%" justifyContent="space-between">
						<Flex flexDir="column">
							{NavItems[userMenu].map((item, index) => (
								<Flex
									alignItems="center"
									justifyContent="flex-start"
									gap="10px"
									_hover={{ bgColor: 'blue.800' }}
									padding="10px"
									borderRadius="10px"
									cursor="pointer"
									onClick={() => navigate(item.route)}
								>
									{item.icon}
									<Text fontSize="18px" fontFamily="Poppins" fontWeight="semibold" color="white">
										{item.nome}
									</Text>
								</Flex>
							))}
						</Flex>
						<Flex>
							<Flex
								alignItems="center"
								justifyContent="flex-start"
								bgColor="blue.800"
								width="100%"
								gap="10px"
								_hover={{ bgColor: 'blue.600' }}
								padding="10px"
								borderRadius="10px"
								cursor="pointer"
								onClick={() => logout()}
							>
								<TbLogout color="white" />
								<Text fontSize="18px" fontFamily="Poppins" fontWeight="semibold" color="white">
									Logout
								</Text>
							</Flex>
						</Flex>
				</Flex>
					
				</GridItem>
			</Show>
			<GridItem pl="0.5" area={'main'} overflow="hidden" >
				{children}
			</GridItem>
			<ModalPerfil isOpen={isOpen} onClose={onClose} onOpen={onOpen} user={user}/>
			<Drawer onClose={menuDisclosure.onClose} isOpen={menuDisclosure.isOpen} size="full">
			<DrawerOverlay />
				<DrawerContent  bgColor="blue.600">
				<DrawerCloseButton />
				<DrawerHeader>
				<Flex
							gap="10px"
							alignItems="center"
							padding="10px"
							_hover={{
								bgColor: 'blue.900',
								borderRadius: '10px',
								cursor: 'pointer'
							}}
							onClick={()=>onOpen()}
						>
							<Avatar size={"md"} src={user?.avatar} />
							<Flex flexDir="column">
								<Text fontSize="16px" fontFamily="Poppins" fontWeight="regular" color="white">
									{user?.name}
								</Text>
								<Text fontSize="12px" fontFamily="Poppins" fontWeight="thin" color="white">
									{user?.userType}
								</Text>
							</Flex>
						</Flex>		
				</DrawerHeader>
				<DrawerBody>
				<Flex flexDir="column" justifyContent="space-between" height="100%" >
						<Flex flexDir="column" paddingRight="50px">
							{NavItems[userMenu].map((item, index) => (
								<Flex
									alignItems="center"
									justifyContent="flex-start"
									gap="10px"
									_hover={{ bgColor: 'blue.800' }}
									padding="10px"
									borderRadius="10px"
									cursor="pointer"
									onClick={() => navigate(item.route)}
								>
									{item.icon}
									<Text fontSize="18px" fontFamily="Poppins" fontWeight="semibold" color="white">
										{item.nome}
									</Text>
								</Flex>
							))}
							<Flex
								alignItems="center"
								justifyContent="flex-start"
								bgColor="blue.800"
								width="100%"
								gap="10px"
								_hover={{ bgColor: 'blue.600' }}
								padding="10px"
								borderRadius="10px"
								cursor="pointer"
								onClick={() => logout()}
							>
								<TbLogout color="white" />
								<Text fontSize="18px" fontFamily="Poppins" fontWeight="semibold" color="white">
									Logout
								</Text>
							</Flex>
						</Flex>
					</Flex>
				</DrawerBody>
				</DrawerContent>
			</Drawer>
		</Grid>
	);
};
export default DashLayout;