import DashLayout from '../../components/DashLayout';
import {
	Avatar,
	Box,
	Button,
	Flex,
	Input,
	InputGroup,
	InputLeftElement,
	Spinner,
	Stack,
	Switch,
	Table,
	TableCaption,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
	useToast
} from '@chakra-ui/react';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { useCallback, useEffect, useState } from 'react';
import { disableUser, listarProfessores } from '../../services/api';
import { AlunosType } from '../Alunos';
import { DrawerCreate } from './DrawerCreate';
import { DrawerUpdate } from './DrawerUpdate';

export interface ProfessoresGet {
	id: string;
	nome: string;
	sobrenome: string;
	email: string;
	cpf: string;
	userId: string;
	user: {
		avatarUrl: string;
		ativo: boolean;
	};
}

const ProfessoresDefault: ProfessoresGet = {
	id: '',
	nome: '',
	sobrenome: '',
	email: '',
	cpf: '',
	userId: '',
	user: {
		avatarUrl: '',
		ativo: true
	}
};

export const Professores = () => {
	const toast = useToast();
	const createDisclosure = useDisclosure();
	const updateDisclosure = useDisclosure();

	const [ professores, setProfessores ] = useState<ProfessoresGet[]>([]);
	const [ professorUpdate, setProfessorUpdate ] = useState<ProfessoresGet>(ProfessoresDefault);
	const [ loading, setLoading ] = useState<boolean>(true);
	const [ updateData, setUpdateData ] = useState<boolean>(true);
	const [ search, setSearch ] = useState<string>('');

	const buscarProfessores = useCallback(() => {
		setLoading(true);
		listarProfessores()
			.then((response) => {
				setLoading(false);
				setProfessores(response.data);
				setUpdateData(false);
			})
			.catch((error) => {
				if (!error.response) return;
				setLoading(false);
				toast({
					title: `Não foi possível listar alunos.`,
					status: 'error',
					isClosable: true
				});
			});
	}, []);

	useEffect(
		() => {
			buscarProfessores();
		},
		[ updateData ]
	);

	const deleteUser = (id: string, value: boolean) => {
		const result = value ? 'habilitado' : 'desabilitado';
		const action = value ? 'habilitar' : 'desabilitar';
		setLoading(true);
		disableUser(id, value)
			.then(() => {
				setUpdateData(true);
				setLoading(false);
				toast({
					title: `Professor(a) ${result}`,
					status: 'success',
					isClosable: true
				});
			})
			.catch((error) => {
				if (!error.response) return;
				setLoading(false);
				toast({
					title: `Não foi possível ${action} professor.`,
					status: 'error',
					isClosable: true
				});
			});
	};

	const reload = () => {
		buscarProfessores();
	};

	return (
		<DashLayout>
			<Flex width="100%" maxW="100%" height="100%" flexDir="column">
				<Flex width="100%" backgroundColor="white" height="130px" padding="20px" flexDir="column">
					<Text fontSize="24px" marginBottom="10px">
						Professores
					</Text>
					<Flex gap="20px">
						<InputGroup gap={'20px'}>
							<InputLeftElement pointerEvents="none" color={'gray.600'}>
								<FaSearch />
							</InputLeftElement>
							<Input
								type="tel"
								placeholder="Buscar professores..."
								onChange={(e) => setSearch(e.target.value)}
								value={search}
								border="none"
								bgColor="gray.300"
							/>
							<Button
								leftIcon={<FaPlus />}
								colorScheme="blue"
								variant="solid"
								onClick={() => createDisclosure.onOpen()}
							>
								Novo
							</Button>
						</InputGroup>
					</Flex>
				</Flex>
				<Flex width="100%" bgColor="white" height="90%">
					{!loading ? (
						<Box
							overflowY="auto"
							overflowX="auto"
							width={{ base: '80vw', md: '100vw', sm: '100vw' }}
							paddingBottom="200px"
						>
							<Table variant="simple">
								<TableCaption>Professores</TableCaption>
								<Thead position="sticky" top={0} bgColor="white" zIndex="999">
									<Tr>
										<Th>Nome</Th>
										<Th>Cpf</Th>
										<Th>Email</Th>
										<Th>Status</Th>
									</Tr>
								</Thead>
								<Tbody>
									{professores.map((professor, index) => {
										if (
											Object.values(professor)
												.map((variavel) => variavel)
												.reduce((a, b) => (b = a + ' ' + b))
												.toLowerCase()
												.includes(search.toLowerCase())
										)
											return (
												<Tr _hover={{ bgColor: 'gray.100', cursor: 'pointer' }} key={index}>
													<Td
														onClick={() => {
															setProfessorUpdate(professor);
															updateDisclosure.onOpen();
														}}
													>
														<Flex gap={'10px'} alignItems={'center'}>
															<Avatar
																size="sm"
																name={professor.nome + ' ' + professor.sobrenome}
																src={professor.user.avatarUrl}
															/>
															{professor.nome + ' ' + professor.sobrenome}
														</Flex>
													</Td>
													<Td
														onClick={() => {
															setProfessorUpdate(professor);
															updateDisclosure.onOpen();
														}}
													>
														{professor.cpf}
													</Td>
													<Td
														onClick={() => {
															setProfessorUpdate(professor);
															updateDisclosure.onOpen();
														}}
													>
														{professor.email}
													</Td>
													<Td>
														<Stack align="center" direction="row">
															<Switch
																size="lg"
																isChecked={professor.user.ativo}
																onChange={() =>
																	deleteUser(professor.userId, !professor.user.ativo)}
															/>
														</Stack>
													</Td>
												</Tr>
											);
									})}
								</Tbody>
							</Table>
						</Box>
					) : (
						<Flex width="100%" height="90%" justifyContent="center" alignItems="center" flexDir="column">
							<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
							<Text fontSize="24px" fontWeight="bold">
								Carregando...
							</Text>
						</Flex>
					)}
				</Flex>
			</Flex>
			<DrawerCreate
				onOpen={createDisclosure.onOpen}
				onClose={createDisclosure.onClose}
				isOpen={createDisclosure.isOpen}
				afterClose={() => {}}
			/>
			<DrawerUpdate
				isOpen={updateDisclosure.isOpen}
				onOpen={updateDisclosure.onOpen}
				onClose={updateDisclosure.onClose}
				afterClose={reload}
				professor={professorUpdate}
			/>
		</DashLayout>
	);
};
