import React, { useCallback, useEffect, useState } from 'react';
import {
	Avatar,
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	FormLabel,
	Input,
	Select,
	Spinner,
	Stack,
	Text,
	useToast
} from '@chakra-ui/react';
import { ProfessoresGet } from '../Professores';
import { listarBooks, updateAula } from '../../services/api';
import { AlunosType } from '.';
import { BookGet } from '../Books';
import useAuth from '../../hooks/useAuth';

export type AulaDrawerProps = {
	id:string;
	alunoId: string;
	materialId: string;
	titulo: string;
	professorId: string;
	data: string;
	status: string;
};

type DrawerCreateProps = {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
	afterClose: () => void;
	aluno: AlunosType;
	aula: AulaDrawerProps;
};

export function DrawerUpdate({ isOpen, onOpen, onClose, afterClose, aluno, aula }: DrawerCreateProps) {
	const toast = useToast();

	const [ loading, setLoading ] = useState(false);

	const [ titulo, setTitulo ] = useState<string>(aula.titulo);
	const [ data, setData ] = useState<string>(aula.data);
	const [ materialId, setMaterialId ] = useState<string>(aula.materialId);
	const [ books, setBooks ] = useState<BookGet[]>([]);
	const {user, functionId} = useAuth();

	const buscarMateriais = useCallback(
		() => {
			listarBooks()
				.then((response) => {
					setBooks(response.data);
				})
				.catch((error) => {
					if (!error.response) return;
					toast({
						title: `Não foi possível listar os materiais.`,
						status: 'error',
						isClosable: true
					});
				});
		},
		[ books ]
	);

	useEffect(
		() => {
			buscarMateriais();
			setData(aula.data);
			setTitulo(aula.titulo);
			setMaterialId(aula.materialId);
		},
		[ isOpen ]
	);

	const handleSalvar = () => {
		if (!aluno || !data || !titulo || !materialId) {
			toast({
				title: `Todos os campos são obrigatórios!`,
				status: 'error',
				isClosable: true
			});
		} else {
			setLoading(true);
			updateAula(aula.id,{
				data,
				alunoId: aluno.id,
				professorId: functionId,
				status: 'AGENDADA',
				titulo,
				materialId
			})
				.then(() => {
					setLoading(false);
					toast({
						title: `Aula atualizada com sucesso.`,
						status: 'success',
						isClosable: true
					});
					onClose();
					afterClose();
				})
				.catch((error) => {
					setLoading(false);
					if (!error.response) return;
					toast({
						title: `Não foi possível atualizar nova aula.`,
						status: 'error',
						isClosable: true
					});
				});
		}
	};

	const resetForm = () => {
		setTitulo('');
		setData('');
	};

	return (
		<Drawer
			isOpen={isOpen}
			placement="right"
			onClose={() => {
				resetForm();
				onClose();
				afterClose();
			}}
		>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader borderBottomWidth="1px">Nova Aula</DrawerHeader>

				<DrawerBody>
					{loading ? (
						<Flex width="100%" height="90%" justifyContent="center" alignItems="center" flexDir="column">
							<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
							<Text fontSize="24px" fontWeight="bold">
								Carregando...
							</Text>
						</Flex>
					) : (
						<Stack spacing="24px">
							<Box>
								<FormLabel>Professor</FormLabel>
								<Flex
									gap={'10px'}
									alignItems="center"
									justifyContent="center"
									paddingTop="10px"
									paddingBottom="10px"
									borderWidth="1px"
									borderColor="gray.200"
									borderRadius="10px"
								>
									<Avatar size="sm" name={user?.name} src={user?.avatar} />
									{`${user?.name}`}
								</Flex>
							</Box>
							<Box>
								<FormLabel>Aluno</FormLabel>
								<Flex
									gap={'10px'}
									alignItems="center"
									justifyContent="center"
									paddingTop="10px"
									paddingBottom="10px"
									borderWidth="1px"
									borderColor="gray.200"
									borderRadius="10px"
								>
									<Avatar size="sm" name={aluno.nome} src={aluno.user.avatarUrl} />
									{`${aluno.nome} ${aluno.sobrenome}`}
								</Flex>
							</Box>
							<Box>
								<FormLabel>Título *</FormLabel>
								<Input
									placeholder="Insira o Título"
									onChange={(e) => setTitulo(e.target.value)}
									value={titulo}
								/>
							</Box>
							<Box>
								<FormLabel>Data *</FormLabel>
								<Input type="datetime-local" onChange={(e) => setData(e.target.value)} value={data} />
							</Box>
							<Box>
								<FormLabel htmlFor="aluno">Selecione o Material *</FormLabel>
								<Select
									defaultValue="selecione"
									onChange={(e) => {
										setMaterialId(e.target.value);
									}}
									value={materialId}
								>
									<option value={materialId} disabled>
										{books.find(bk => bk.id == materialId)?.nome}
									</option>
									{books &&
										books.map((item, index) => {
											return (
												<option
													value={item.id}
													key={index}
													style={{ color: 'black' }}
												>{`${item.nome}`}</option>
											);
										})}
								</Select>
							</Box>
						</Stack>
					)}
				</DrawerBody>

				<DrawerFooter borderTopWidth="1px">
					<Button variant="outline" mr={3} onClick={onClose} isDisabled={loading}>
						Voltar
					</Button>
					<Button colorScheme="blue" isDisabled={loading} onClick={handleSalvar}>
						Salvar
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
