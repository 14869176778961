import React, { createContext, ReactNode, useCallback, useEffect, useState } from 'react';
import { decodeToken } from 'react-jwt';
import { useNavigate } from 'react-router-dom';
import { auth, AuthType, buscarAlunoPorEmail, buscarAlunoPorId, buscarProfessorPorEmail } from '../services/api';
import { useToast } from '@chakra-ui/react';

interface UserContextProviderProps {
	children: ReactNode;
}

interface UserContextType {
	user?: IUser;
	isLoggedin: boolean;
	setUser: (user: IUser) => void;
	logout: () => void;
	login: (authData: AuthType) => Promise<boolean>;
	functionId: string;
}

export interface IUser {
	avatar: string;
	name: string;
	userType: string | undefined;
	username: string;
	sub: string | undefined;
}

export const UserContext = createContext({} as UserContextType);

function AuthContextProvider({ children }: UserContextProviderProps) {
	const [ user, setUser ] = useState<IUser>();
	const [ isLoggedin, setIsLoggedin ] = useState<boolean>(false);
	const [ functionId, setFunctionId ] = useState<string>('');

	const toast = useToast();
	const navigate = useNavigate();

	useEffect(() => {
		async function validUser() {
			const token = localStorage.getItem('token');
			if (token && !user) {
				const decodedToken = decodeToken<IUser>(token);
				try {
					if (decodedToken) {
						setUser(decodedToken);
						setIsLoggedin(true);
						if (decodedToken.username && decodedToken.userType) {
							var fId = localStorage.getItem('@fId');
							if (fId) {
								setFunctionId(fId);
							} else {
								getFunctionId(decodedToken.username, decodedToken.userType);
							}
						} else {
							localStorage.removeItem('token');
							navigate('/login');
						}
					} else {
						localStorage.removeItem('token');
						navigate('/login');
						setIsLoggedin(false);
					}
				} catch (error) {
					console.error(error);
				}
			} else {
				localStorage.removeItem('token');
				navigate('/login');
				setIsLoggedin(false);
			}
		}
		validUser();
	}, []);

	const getFunctionId = (email: string, userType: string): Promise<void> =>
		new Promise((resolve, reject) => {
			if (userType === 'PROFESSOR') {
				buscarProfessorPorEmail(email)
					.then((res) => {
						setFunctionId(res.data.id);
						localStorage.setItem('@fId', res.data.id);
						resolve();
					})
					.catch((err) => {
						navigate('/login');
						reject(err);
						toast({
							title: err,
							status: 'error',
							isClosable: true
						});
					});
			}
			if (userType === 'ALUNO') {
				buscarAlunoPorEmail(email)
					.then((res) => {
						console.log(res.data);
						setFunctionId(res.data.id);
						localStorage.setItem('@fId', res.data.id);
						resolve();
					})
					.catch((err) => {
						navigate('/login');
						toast({
							title: err,
							status: 'error',
							isClosable: true
						});
						reject(err);
					});
			}
		});

	const logout = () => {
		localStorage.removeItem('token');
		localStorage.removeItem('@fId');
		navigate('/login');
		setIsLoggedin(false);
	};

	const login = (authData: AuthType): Promise<boolean> =>
		new Promise((resolve, reject) => {
			auth(authData)
				.then(async ({ data }) => {
					localStorage.setItem('token', data.access_token.toString());
					const decoded = decodeToken<IUser>(data.access_token.toString());
					if (decoded) {
						setUser(decoded);
						setIsLoggedin(true);
						if (decoded.userType === 'ALUNO') {
							if (decoded.sub) {
								await getFunctionId(decoded.username, decoded.userType);
								setIsLoggedin(true);
								navigate('/portal');
								resolve(true);
							}
						} else {
							if (decoded.userType === 'PROFESSOR') {
								await getFunctionId(decoded.username, decoded.userType);
								setIsLoggedin(true);
							}
							resolve(true);
							navigate('/dashboard');
						}
					} else {
						localStorage.removeItem('token');
						navigate('/login');
						toast({
							title: `Email ou senha incorretos.`,
							status: 'error',
							isClosable: true
						});
						resolve(false);
					}
				})
				.catch(() => {
					setIsLoggedin(false);
					toast({
						title: `Email ou senha incorretos.`,
						status: 'error',
						isClosable: true
					});
					resolve(false);
				});
		});

	return (
		<UserContext.Provider value={{ user, setUser, logout, isLoggedin, login, functionId }}>
			{children}
		</UserContext.Provider>
	);
}

export default AuthContextProvider;
