import { Route, Routes, Navigate } from 'react-router-dom';
import Login from '../pages/Login';
import ChangePassword from '../pages/ChangePassword';
import ForgotPassword from '../pages/ForgotPassword';
import MateriaisId from '../pages/Materiais/MaterialAula';
import MateriaisIdBook from '../pages/Materiais/Material';
import Portal from '../pages/Portal';

export const PublicRoutes = () => {
	return (
		<Routes>
			<Route path="*" element={<Navigate to="/login" replace={true} />} />
			<Route path="/change-password/:email" element={<ChangePassword />} />
			<Route path="/forgot-password" element={<ForgotPassword />} />
			<Route path="/login" element={<Login />} />
			<Route path="/portal" element={<Portal />} />
			<Route path="/portal/books" element={<Portal />} />
			<Route path="/dashboard/materiais/aula/:aulaId" element={<MateriaisId />} />
			<Route path="/dashboard/materiais/:bookId" element={<MateriaisIdBook />} />
		</Routes>
	);
};
