import React, { useState } from 'react';
import {
	Box,
	Button,
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerFooter,
	DrawerHeader,
	DrawerOverlay,
	Flex,
	FormLabel,
	Input,
	Spinner,
	Stack,
	Text,
	useToast
} from '@chakra-ui/react';
import { adicionarLesson, deleteLesson } from '../../services/api';
import { toBase64, uploadWithBase64 } from '../../utils/imageHelper';

type DrawerCreateProps = {
	isOpen: boolean;
	onOpen: () => void;
	onClose: () => void;
	bookId: string;
	afterClose: () => void;
};

export function DrawerCreate({ isOpen, onOpen, onClose, afterClose, bookId }: DrawerCreateProps) {
	const toast = useToast();

	const [ loading, setLoading ] = useState(false);

	const [ selectImage, setSelectImage ] = useState(false);
	const [ imageFile, setImageFile ] = useState<File>();
	const [ isUploading, setIsUploading ] = useState(false);

	const [ pdfFile, setPdfFile ] = useState<File>();
	const [ pdf, setPdf ] = useState('');

	const [ nome, setNome ] = useState('');
	const [ canvaUrl, setCanvaUrl ] = useState('');
	const [ capa, setCapa ] = useState('');

	const resetForm = () => {
		setNome('');
		setCanvaUrl('');
		setCapa('');
	};

	const handleSelectedFile = async (files: any) => {
		if (files && files[0].size < 10000000) {
			setSelectImage(true);
			setImageFile(files[0]);
			const picBase64 = await toBase64(files[0]);
			setCapa(`${picBase64}`);
			setImageFile(undefined);
		} else {
			toast({
				title: `Foto muito pesada, selecione outra!`,
				status: 'warning',
				isClosable: true
			});
		}
	};

	const handleSelectedFilePdf = async (files: any) => {
		if (files && files[0].size < 10000000000) {
			setPdfFile(files[0]);
			const picBase64 = await toBase64(files[0]);
			console.log('converteu');
			setPdf(`${picBase64}`);
			setPdfFile(undefined);
		} else {
			toast({
				title: `Foto muito pesada, selecione outra!`,
				status: 'warning',
				isClosable: true
			});
		}
	};

	const handleSaveLesson = async () => {
		console.log(nome);
		console.log(capa);
		console.log(pdf);
		if (!nome || !capa || !pdf) {
			toast({
				title: `Todos os campo são obrigatórios`,
				status: 'error',
				isClosable: true
			});
		} else {
			setLoading(true);
			uploadWithBase64(capa).then((link) => {
				uploadWithBase64(pdf).then((pdfLink) => {
					adicionarLesson({
						bookId,
						nome,
						canvaUrl: pdfLink,
						thumbnail: link
					}).then(() => {
						setLoading(false);
						setNome('');
						setCanvaUrl('');
						resetForm();
						afterClose();
						toast({
							title: `Adicionado com sucesso!`,
							status: 'success',
							isClosable: true
						});
					});
				});
			});
		}
	};

	return (
		<Drawer
			isOpen={isOpen}
			placement="right"
			onClose={() => {
				resetForm();
				onClose();
				afterClose();
			}}
		>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader borderBottomWidth="1px">Nova Lesson</DrawerHeader>

				<DrawerBody>
					{loading ? (
						<Flex width="100%" height="90%" justifyContent="center" alignItems="center" flexDir="column">
							<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
							<Text fontSize="24px" fontWeight="bold">
								Salvando...
							</Text>
						</Flex>
					) : (
						<Stack spacing="24px">
							<Box>
								<FormLabel htmlFor="Nome">Nome da Lesson *</FormLabel>
								<Input
									placeholder="Insira o Nome da Lesson"
									onChange={(e) => setNome(e.target.value)}
									value={nome}
								/>
							</Box>
							<Box>
								<FormLabel htmlFor="CanvaUrl">Pdf *</FormLabel>
								<Input
									type="file"
									accept="application/pdf"
									onChange={(files) => handleSelectedFilePdf(files.target.files)}
								/>
							</Box>
							<Box
								width="100%"
								height="150px"
								borderWidth="1px"
								borderColor="gray.200"
								borderRadius="10px"
								bgPosition="center"
								bgSize="cover"
								bgImage={capa}
							/>
							<Box>
								<FormLabel htmlFor="Capa">Capa *</FormLabel>
								<Input
									type="file"
									accept="image/*"
									onChange={(files) => handleSelectedFile(files.target.files)}
								/>
							</Box>
						</Stack>
					)}
				</DrawerBody>

				<DrawerFooter borderTopWidth="1px">
					<Button variant="outline" mr={3} onClick={onClose} isDisabled={loading}>
						Voltar
					</Button>
					<Button colorScheme="blue" isDisabled={loading} onClick={handleSaveLesson}>
						Salvar
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}
