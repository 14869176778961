import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import useAuth from '../../../hooks/useAuth';
import { buscarAlunoPorEmail, listarAulasPorAluno, listarBooks, listarBooksPorAluno } from '../../../services/api';
import { Badge, Flex, Skeleton, Stack, Text, useToast} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { BookGet } from '../../Books';
import { useNavigate } from 'react-router-dom';

interface AulaAluno {
	id: string;
    titulo : string;
    alunoId : string;
    materialId: string;
    professorId: string;
    data: string;
    status: string;
    professor: {
      nome: string;
      sobrenome: string;
      email: string;
      userId: string;
      avatarUrl: string;
    }
}

export default function PortalMateriais() {
	const { user, functionId } = useAuth();
	const [aulas, setAulas] = useState<AulaAluno[]>([]);
	const [loading, setLoading] = useState(true);
	const [ books, setBooks ] = useState<BookGet[]>([]);
    const toast = useToast();
    const navigate = useNavigate();
	useEffect(()=>{
		user?.username && buscarAlunoPorEmail(user?.username).then((response)=>{
			listarAulasPorAluno(response.data.id).then((response)=>{
				setAulas(response.data);
				setLoading(false)
			})
		});
	}, []);

    const buscarBooks = useCallback(
		() => {
			setLoading(true);
			listarBooksPorAluno(functionId)
				.then((response) => {
					setBooks(response.data);
					setLoading(false);
				})
				.catch((err) => {
					toast({
						title: `Não foi possível carregar o livro${err}`,
						status: 'error',
						isClosable: true
					});
				});
		},
		[ books ]
	);

	useEffect(() => {
		buscarBooks();
	}, []);
    
	return (
		<>
			<NavBar />
			<Flex 
                width={'100%'} 
                minH={"85vh"}  
                justifyContent="center" 
                bgColor="gray.200" 
                overflowX="unset"
                padding="50px"
            >
                <Flex 
                    gap="10px" 
                    width="90%"
                    flexDir="column"
                    alignItems="center"
                >
                    <Text fontSize="32px" fontWeight="800">Materiais</Text>
                    <Flex gap="10px" flexWrap="wrap">

                        {
                            loading ? 
                            <Flex width={'90%'}>
                                <Stack>
                                    <Skeleton height='40px' />
                                    <Skeleton height='40px' />
                                    <Skeleton height='40px' />
                                </Stack>
                            </Flex>
                            :
                            books && books.map((book, index) => (
                                <Flex
                                    width="250px"
                                    height="210px"
                                    bgColor="blue.700"
                                    borderRadius="25px"
                                    alignItems="center"
                                    justifyContent="center"
                                    flexDir="column"
                                    gap="10px"
                                    borderWidth="3px"
                                    borderColor="white"
                                    boxShadow="lg"
                                    _hover={{
                                        backgroundColor:"blue.800",
                                        cursor:"pointer"
                                    }}
                                    onClick={()=>{
                                        navigate(`/portal/books/${book.id}`)
                                    }}
                                >
                                    <Flex
                                        height="130px"
                                        bgImage={book.capa}
                                        minWidth="230px"
                                        maxWidth="230px"
                                        bgPosition="center"
                                        bgSize="cover"
                                        borderRadius="10px"
                                    />
                                    <Flex alignItems="center" gap="10px" justifyContent="center">
                                        <Flex flexDir="column">
                                            <Text color="white" fontSize="20px" fontWeight="800" textAlign="center">
                                                {book.nome}
                                            </Text>
                                            <Flex gap="10px">
                                                <Badge>{book.idioma}</Badge>
                                                <Badge colorScheme="blue">{book.nivel}</Badge>
                                            </Flex>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))
                        }
                    </Flex>
                </Flex>
			</Flex>
			<Footer />
		</>
	);
}
