import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import useAuth from '../../../hooks/useAuth';
import { buscarBookPorId } from '../../../services/api';
import { Box, Button, Flex, Text, useToast} from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';
import { BookGet } from '../../Books';
import { useParams } from 'react-router-dom';
import { Lesson } from '../../Lessons/Index';
import { BsFileEarmarkMedicalFill } from 'react-icons/bs';



export default function PortalLessons() {
	const { user } = useAuth();
	const [loading, setLoading] = useState(true);
    const [ lessons, setLessons ] = useState<Lesson[]>([]);
	const [ book, setBook ] = useState<BookGet>();
    const toast = useToast();
    const {bookId} = useParams();

    const buscarBook = useCallback(
		() => {
			setLoading(true);
			buscarBookPorId(`${bookId}`)
				.then((response) => {
					var lessonsData = response.data.lessons;
					setBook(response.data);
					setLessons(lessonsData.sort());
					setLoading(false);
				})
				.catch((err) => {
					toast({
						title: `Não foi possível carregar o livro${err}`,
						status: 'error',
						isClosable: true
					});
				});
		},
		[ book, lessons ]
	);
    function openTab(bookId: string) {
		window.open(`/dashboard/materiais/${bookId}`, '', 'popup,width=1080,height=660, left=300, top=500');
	}
	useEffect(() => {
		buscarBook();
	}, []);

    function justNumbers(text:string) {
        var numbers = text.replace(/[^0-9]/g,'');
        return parseInt(numbers);
    }
    
	return (
		<>
			<NavBar />
			<Flex 
                width={'100%'} 
                minH={"85vh"}  
                justifyContent="center" 
                bgColor="gray.200" 
                overflowX="unset"
                padding="50px"
            >
                <Flex 
                    gap="10px" 
                    width="90%"
                    flexDir="column"
                    alignItems="center"
                >
                    <Text fontSize="26px" fontWeight="800">{book?.idioma}</Text>
                    <Text fontSize="32px" fontWeight="800">Book/{book?.nome}</Text>
                    <Flex gap="10px" bgColor="gray.400" borderRadius="10px" boxShadow="lg" justifyContent="center" alignItems="center" p="5px">
                        <Button variant="solid" colorScheme='blue'  onClick={()=>{
                            openTab(`${book?.id}`)
                        }} isDisabled={loading}>
                            Abrir o livro
                        </Button>
                    </Flex>
                    <Text fontSize="32px" fontWeight="800">Homeworks</Text>
                    {
                        lessons.     
                        sort((a, b) => {
                            if (Number(a.nome.split(' ')[1]) < Number(b.nome.split(' ')[1])) return -1;
                            if (Number(a.nome.split(' ')[1]) > Number(b.nome.split(' ')[1])) return 1;
                            return 0;
                        }).              
                        map((lesson, index)=>
                            lesson.homeworks.
                            sort().
                            sort((a, b) => Number(justNumbers(b.nome)) - Number(justNumbers(a.nome))).
                            map((home,index)=>
                                <Box
                                    width="50%"
                                    height="50px"
                                    borderWidth="1px"
                                    borderColor="gray.200"
                                    borderRadius="10px"
                                    boxShadow="sm"
                                    bgColor="white"
                                    cursor="pointer"
                                    onClick={()=>{
                                        window.open(home.materialUrl,'_blank');
                                    }}
                                >
                                    <Flex
                                        height="100%"
                                        justifyContent="space-around"
                                        alignItems="center"
                                    >
                                        <Flex 
                                            justifyContent="center"
                                            alignItems="center"
                                            gap="10px"
                                        >
                                            <BsFileEarmarkMedicalFill size="20px"/>
                                            <Text fontSize="18px" fontWeight="semibold" >
                                                {home.nome}
                                            </Text>
                                        </Flex>
                                        
                                    </Flex>
                                </Box>
                            )
                        )
                    }
                    
                </Flex>
			</Flex>
			<Footer />
		</>
	);
}
